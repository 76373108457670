import { useEffect, useState, useRef } from "react";
import changeHeight from "../../../data/changeHeight";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Form, Input, Select, Radio, Space, Button, notification, InputNumber } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import locale from 'antd/es/date-picker/locale/es_ES';
import aseguradoraBanco from "../../../data/aseguradora-banco";
import axios from "axios";
dayjs.extend(customParseFormat);


const FormularioParte2 = ({ datos, setDatosForm, onClick }) => {

    const refForm2 = useRef(0);
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [sendButtonText, setSendButtonText] = useState("Generar presupuesto");
    const cambioForm = onClick;
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        changeHeight(refForm2.current.clientHeight);
    })

    useEffect(() => {
        form.setFieldsValue({ email: datos.email, phone: datos.tel });
    }, [datos, form])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === refForm2.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(refForm2.current);
    
        return () => {
          resizeObserver.unobserve(refForm2.current);
        };
      }, []);

    const onFinish = (values) => {

        console.log(values)

        setbuttonLoading(true);
        setSendButtonText("Enviando...");
        setTimeout(() => {
            setSendButtonText("Generando presupuesto...");
        }, 3000);
        values["url"] = datos.url;
        values["procedencia"] = datos.procedencia;


        const headers = {
            "Content-Type": "application/json"
        }

        axios.post(process.env.REACT_APP_URL_CALCULO_PRESUPUESTO, values, { headers: headers }).then(function (response) {
            values["presupuesto_id"] = response.data.presupuesto_id
            values["poliza_nueva"] = response.data.poliza_nueva
            values["sumar_asegurada"] = response.data.sumar_asegurada
            values["pago_anual"] = response.data.pago_anual
            values["pago_semestral"] = response.data.pago_semestral
            values["pago_trimestral"] = response.data.pago_trimestral
            values["pago_mensual"] = response.data.pago_mensual
            values["presupuesto_url"] = response.data.presupuesto_url

            let body = {
                nextStep: "3",
                birthday: values.birthday,
                cobertura: values.cobertura,
                dni_texto: values.dni_texto,
                empresa: values.empresa,
                fin_seguro: values.fin_seguro,
                importe_actual: values.importe_actual,
                last_name: values.last_name,
                last_name_2: values.last_name_2,
                name: values.name,
                pago_anual: values.pago_anual,
                pago_mensual: values.pago_mensual,
                pago_semestral: values.pago_semestral,
                pago_trimestral: values.pago_trimestral,
                poliza_nueva: values.poliza_nueva,
                presupuesto_id: values.presupuesto_id,
                presupuesto_url: values.presupuesto_url,
                procedencia: values.procedencia,
                suma_asegurada_introducida: values.suma_asegurada_introducida,
                sumar_asegurada: values.sumar_asegurada,
                url: values.url,
                id: datos.submissionId,
            }

            axios.patch('https://5xkk6tarwqfnjwy44g64ip4jjq0ofuzn.lambda-url.eu-west-3.on.aws/', body, { headers: headers }).then(function (response) {

            if (response.status === 200) {


                setDatosForm(values);
                cambioForm()
                setbuttonLoading(false);
                setSuccess(true);
    
            }
    
            }).catch(function (error) {
                console.log(error)
                setbuttonLoading(false);
                setSendButtonText("Generar presupuesto");
                openNotification('bottom', 'Ha ocurrido un error inesperado, por favor inténtelo más tarde')
            });
        })
            .catch(function (error) {
                setbuttonLoading(false);
                setSendButtonText("Generar presupuesto");
                if (error.response.data.message !== undefined) {
                    openNotification('bottom', error.response.data.message);
                } else {
                    openNotification('bottom', 'Ha ocurrido un error inesperado, por favor inténtelo más tarde')
                }
            });

    };

    const openNotification = (placement, message) => {
        api.info({
            message: 'Ha ocurrido un error',
            description: `${message}`,
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const disabledDateBeforeToday = (current) => {
        // Can not select days before today, today yes
        return current && current <= dayjs().endOf('day');
    };

    const disabledDateInRange = () => {
        const startDate = dayjs().subtract(18, 'year').startOf('day');
        const endDate = dayjs().add(100, 'year').startOf('day');

        return (current) => {
            // Disable dates within the range (inclusive)
            return current && current > startDate && current < endDate;
        };
    };

    return (
        <>
            {contextHolder}
            <div ref={refForm2}>
                <div style={{ padding: '1.5rem' }}>

                <Form
                    form={form}
                    hidden={success}
                    initialValues={{ remember: true }}
                    name="basic2"
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email? *</p>}
                        name="email"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}>
                        <Input placeholder="tunombre@gmail.com" type="email" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos llamar? *</p>}
                        name="phone"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}>
                        <Input placeholder="619 99 31 31" type="tel" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre *</p>}
                        name="name"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu nombre' }]}>
                        <Input placeholder="Felipe" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Primer apellido *</p>}
                        name="last_name"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu primer apellido' }]}>
                        <Input placeholder="Gomez" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Segundo apellido *</p>}
                        name="last_name_2"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu segundo apellido' }]}>
                        <Input placeholder="Lopez" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Fecha de nacimiento *</p>}
                        name="birthday"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu fecha de nacimiento' }]}>
                        <DatePicker style={{ width: '100%' }} locale={locale} disabled={buttonLoading} format="DD/MM/YYYY" showToday={false}
                            disabledDate={disabledDateInRange()} defaultPickerValue={dayjs().subtract(18, 'year').endOf('day')} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>DNI *</p>}
                        name="dni_texto"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa tu DNI' }]}>
                        <Input placeholder="12345678A" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cúantos pagas al año por tu seguro de vida? (€) *</p>}
                        name="importe_actual"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa la cantidad que pagas por tu seguro de vida' }]}>
                        <Input placeholder="700" type="number" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Fecha en la que quieres que comience tu seguro? *</p>}
                        name="fin_seguro"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa la fecha en la que quieres que comience tu seguro' }]}
                        tooltip={{ title: '(Suele coincidir con la fecha de vencimiento de tu seguro actual)' }}>
                        <DatePicker disabledDate={disabledDateBeforeToday} style={{ width: '100%' }} locale={locale} disabled={buttonLoading} format="DD/MM/YYYY" showToday={false} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es tu suma asegurada? (€) *</p>}
                        name="suma_asegurada_introducida"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingersa la suma asegurada' }]}>
                        <InputNumber placeholder="10000" disabled={buttonLoading} max={600000} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Compañía previa *</p>}
                        name="empresa"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, introduzca la compañia previa' }]}>
                        <Select showSearch placeholder='Escribe el nombre de la Aseguradora/Banco' disabled={buttonLoading}>
                            {aseguradoraBanco.map((asegBank) => (
                                <Select.Option key={asegBank} value={asegBank}> {asegBank} </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Cobertura *</p>}
                        name="cobertura"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, introduzca la cobertura' }]}>
                        <Radio.Group onChange={onChange} value={value} disabled={buttonLoading}>
                            <Space direction="vertical" style={{ marginLeft: '1rem' }}>
                                <Radio value={"Fallecimiento por cualquier causa"}>Fallecimiento por cualquier causa</Radio>
                                <Radio value={"Fallecimiento e Invalidez por cualquier causa"}>Fallecimiento e Invalidez por cualquier causa</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0.6rem" }}>
                        <Button style={{ width: "100%", height: "35px", marginTop:"0.8rem" }} type='primary' loading={buttonLoading} htmlType="submit">
                            {sendButtonText}
                        </Button>
                    </Form.Item>
                </Form>


                </div>
            </div>


        </>
    );

}

export default FormularioParte2;