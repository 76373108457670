import React, { useState, useEffect, useRef } from 'react';
import { Form, ConfigProvider, notification, Button, Row, Col, theme } from 'antd';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import changeHeight from '../../data/changeHeight';
import axios from 'axios';
import FormParte1 from './components/FormParte1';
import FormParte2 from './components/FormParte2';

const FormCalcularHipoteca = () => {
    const [formData1, setFormData1] = useState(null);
    const [calculatedData, setCalculatedData] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [isDark, setIsDark] = useState(false);
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const [api, contextHolder] = notification.useNotification();

    const [paso, setPaso] = useState(0);

    //Recibe los datos cuando termina el FormParte2
    const handleCalcular = async (values) => {
        if (formData1 && values) {
            const combinedData = { ...formData1, ...values };

            try {
                const response = await axios.post(`${process.env.REACT_APP_URL_CALCULADORA}`, combinedData);

                if (response.data) {
                    const calculadoData = { ...response.data.response1, ...response.data.response2 };

                    console.log(calculadoData);
                    setCalculatedData(calculadoData);

                    setPaso(2); // Cambia el estado para mostrar los datos calculados
                } else {
                    console.error('Error en la respuesta:', response);
                }
            } catch (error) {
                console.error('Error al hacer la solicitud:', error);
            }
        }

    };

    const handleReset = () => {
        setButtonLoading(false);
        setPaso(0);
    }

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: decodeURIComponent(searchParams.get("parentUrl")) });
        }
        if (searchParams.get("dark")) {
            isDark === 'true' ? setIsDark(false) : setIsDark(true);
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    var tokenTheme = {}

    if (isDark === true) {
        tokenTheme = {
            algorithm: theme.darkAlgorithm,
            primaryColor: primaryColor,
        }
    }
    else {
        tokenTheme = {
            algorithm: theme.lightAlgorithm,
            primaryColor: primaryColor,
        }
    }

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={tokenTheme}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>

                <div ref={refForm}>
                    {paso === 0 ? (
                        <FormParte1
                            form={form}
                            onFinishParte1={(values) => {
                                setFormData1(values);
                                setPaso(1);
                            }}
                            buttonLoading={buttonLoading}
                            primaryColor={primaryColor}
                            tokenTheme={tokenTheme}
                        />
                    ) : paso === 1 ? (
                        <FormParte2
                            form={form}
                            onFinishParte2={(values) => {
                                setButtonLoading(true);
                                handleCalcular(values);
                            }}
                            buttonLoading={buttonLoading}
                            primaryColor={primaryColor}
                            handleCalcular={handleCalcular}
                        />
                    ) : paso === 2 && (
                        calculatedData && (
                            <Row justify={'center'} align={'middle'} style={{ top: '50%' }} gutter={[20, 15]}>
                                <Col span={20}>
                                    <h2>Ahorro en su hipoteca</h2>
                                </Col>
                                <Col span={20}>
                                    <p><b>Cambiando hoy el seguro a Coventia el ahorro anual ascendería a {calculatedData.anual_savings}€</b>,  y como el coste del seguro del banco se va a incrementar a lo largo de la vida del préstamo, al terminar la hipoteca el ahorro total sería de {calculatedData.total_savings}.</p>
                                </Col>
                                <Col span={20}>
                                    <Button type='primary' style={{ width: "100%", height: "35px" }} href={calculatedData.documento_url} target='_blank'>
                                        Descargar PDF
                                    </Button>
                                </Col>
                                <Col span={20}>
                                    <Button type="link" block style={{ marginTop: '2%', marginBottom: '1%' }} onClick={handleReset}>
                                        Volver a empezar
                                    </Button>
                                </Col>
                            </Row>
                        )
                    )}
                </div>
            </ConfigProvider>
        </>
    );
}

export default FormCalcularHipoteca;