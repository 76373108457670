import { Button, Divider } from "antd";
import changeHeight from "../../../data/changeHeight";
import { useEffect, useRef, useState } from "react";

const ResultPaginaPrincipalParte2 = ({ datos, onClick }) => {

    const refResult2 = useRef(0);
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        changeHeight(refResult2.current.clientHeight)
    })

    useEffect(() => {
        setNombre(datos.name)
    }, [datos])




    return (
        <>
            <div ref={refResult2}>
                <div id="leadLargoSelfBank" style={{ padding: '2rem' }}>
                    <p>¡Muchas gracias {nombre}!</p>
                    <p>Puedes descargar el presupuesto desde aquí:</p>
                    <Button block ghost type="primary" target="_blank" href={datos.presupuesto_url} >Descargar prespuesto</Button>
                    <Divider />
                    <p>Estás a tan solo 30 segundos de conseguir el ahorro en tu póliza</p>
                    <Button block type='primary' onClick={onClick}>Realizar una solicitud</Button>
                    <Divider />
                    <p>Si prefieres realizar la contratación junto a un asesor, puedes llamar al 919 99 31 31 o concertar una cita</p>
                    <Button block ghost type="primary" href="https://meetings-eu1.hubspot.com/santiago-gatti" target="_blank">Concertar una cita</Button>
                </div>
            </div>
        </>
    );

}

export default ResultPaginaPrincipalParte2;