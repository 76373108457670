import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, ConfigProvider, InputNumber, Radio, Space, notification, Checkbox, DatePicker, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import ResultParte1 from './components/ResultParte1'
import ResultParte2 from './components/ResultParte2'
import changeHeight from '../../data/changeHeight';
import FormularioSingularParte2 from "./components/FormularioSingularParte2";
import FormularioParte3 from './components/FormularioSingularParte3';
import axios from 'axios';
import ResultPaginaPrincipalParte3 from './components/ResultParte3';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const dateFormat = 'DD/MM/YYYY';

const FormPaginaPrincipalSingularParte1 = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const [value, setValue] = useState("");
    const [datosForm, setDatosForm] = useState({});
    const [paso, setPaso] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [NotEnsureFields, setNotEnsureFields] = useState(false);
    const [priceBasic, setPriceBasic] = useState(null);
    const [pricePremium, setPricePremium] = useState(null);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    //Cálculo edad

    const calculateAge = (birthdate) => {
        if (!birthdate) return null;
        return dayjs().diff(dayjs(birthdate), 'years');
    };

    const handleBirthdayChange = (date) => {
        form.setFieldsValue({ capital_to_ensure: null });
    };

    const disabledDateBefore18YearsAgo = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'years');
        return current && current > eighteenYearsAgo;
    };

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: decodeURIComponent(searchParams.get("parentUrl")) });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true);
        values.singular = true;

        const headers = {
            "Content-Type": "application/json"
        }

        axios.post(process.env.REACT_APP_URL_CALCULO_RAPIDO, values, {
            headers: headers
        })
            .then(function (response) {
                if (response.data.message === "OK") {
                    setDatosForm(values);
                    if (values.procedencia === 'NoSeguro') {
                        setPriceBasic(response.data.priceData.priceBasic);
                        setPricePremium(response.data.priceData.pricePremium);
                    } else {
                        setPriceBasic(null);
                        setPricePremium(null);
                    }
                    setbuttonLoading(false);
                    setSuccess(true);
                    setPaso(1);
                    console.log(values);
                }
            })
            .catch(function () {
                openNotification('bottom')
                setbuttonLoading(false);
            });
    };

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'NoSeguro') {
            setNotEnsureFields(true);
        } else setNotEnsureFields(false);
    };

    const changePrivacy = (e) => {
        form.setFieldsValue({ privacy: false });
        if (e.target.checked) {
            setPrivacyChecked(true);
            setOpenPrivacy(true);
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.target === refForm.current) {
                        changeHeight(entry.target.clientHeight);
                        break;
                    }
                }
            });
    
            resizeObserver.observe(refForm.current);
            return;
        }
        setPrivacyChecked(false);
        setOpenPrivacy(false);
    }


    const privacyPopOk = () => {
        setPrivacyChecked(true);
        setOpenPrivacy(false);
        form.setFieldsValue({ privacy: true });
    }

    const privacyPopCancel = () => {
        setPrivacyChecked(false);
        setOpenPrivacy(false);
        form.setFieldsValue({ privacy: false });
    }

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const customParser = (value) => {
        // Reemplaza comas por puntos
        const processedValue = value.replace(/,/g, '.');
      
        // Analiza el valor en un número de punto flotante
        const parsedValue = parseFloat(processedValue);
      
        // Asegúrate de que el resultado es un número
        if (!isNaN(parsedValue)) {
          return parsedValue;
        }
      
        return undefined; // Si no se pudo analizar, devuelve undefined
      };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                locale={esES}
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                    components: {
                        // Tenemos qeu cambiarle el tamaño al texto descripcion del popconfirm
                        Popconfirm: {
                            fontSize: "13px",
                        }
                    }
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Form
                        form={form}
                        style={{ padding: '1.5rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                        requiredMark={false}
                    >
                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="userAgent"
                            name="userAgent">
                            <Input type='' />
                        </Form.Item>
                        {!NotEnsureFields && (
                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuánto pagas al año por tu seguro de vida? (€) *</p>}
                                name="importe"
                                style={{ marginBottom: "0.6rem" }}
                                colon={false}
                                rules={[{ required: true, message: 'Por favor, ingresa el importe de tu seguro' }]}

                                
                            >
                                {/* Se debe permitir tanto comas como puntos como separador decimal */}
                                <InputNumber style={{ width: '100%' }} placeholder='700' disabled={buttonLoading} parser={customParser} min={30} />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Con quién tienes la póliza contratada actualmente? *</p>}
                            name="procedencia"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Indique la procedencia del seguro' }]}
                        >
                            <Radio.Group onChange={onChange} value={value}>
                                <Space
                                    direction='vertical'
                                    style={{ marginLeft: '1rem' }}
                                >
                                    <Radio value={"Aseguradora"} style={{ fontWeight: 'bold' }}>Aseguradora</Radio>
                                    <Radio value={"Banco"} style={{ fontWeight: 'bold' }}>Banco</Radio>
                                    <Radio value={"NoSeguro"} style={{ display: 'none' }}>No tengo seguro a día de hoy</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {NotEnsureFields && (
                            <>
                                <Form.Item
                                    label="Fecha de nacimiento"
                                    name="birthday"
                                    rules={[{ required: true, message: 'Ingresa su fecha de nacimiento' }]}
                                >
                                    <DatePicker defaultPickerValue={dayjs().subtract(18, 'years')} disabledDate={disabledDateBefore18YearsAgo} style={{ width: '100%' }} format={dateFormat} onChange={handleBirthdayChange} showToday={false} />
                                </Form.Item>

                                <Form.Item
                                    label="Cantidad que quieres asegurar"
                                    name="capital_to_ensure"
                                    initialValue={50000}
                                    rules={[
                                        { required: true, message: 'Ingresa la cantidad que quieres asegurar' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const age = calculateAge(getFieldValue('birthday'));

                                                if (age >= 18 && age <= 59) {
                                                    if (value < 50000 || value > 500000) {
                                                        return Promise.reject(new Error('La cantidad debe ser entre 50.000 y 500.000'));
                                                    }
                                                } else if (age >= 60 && age <= 74) {
                                                    if (value < 10000 || value > 50000) {
                                                        return Promise.reject(new Error('La cantidad debe ser entre 10.000 y 50.000'));
                                                    }
                                                } else {
                                                    // Edad mayor de 74 años, bloquea el campo
                                                    return Promise.reject(new Error('Los mayores a 74 años no pueden realizar un seguro'));
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te llamamos? *</p>}
                            name="tel"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input maxLength={9} minLength={9} placeholder='619 99 31 31' type='tel' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar el email? *</p>}
                            name="email"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@gmail.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="privacy" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta la cláusula de privacidad")); } } }]}>
                            <Popconfirm
                                title="¿Aceptas la cláusula de privacidad?"
                                onConfirm={privacyPopOk}
                                onCancel={privacyPopCancel}
                                okText="Aceptar"
                                okButtonProps={{ style: { fontWeight: 'bold' } }}
                                okType='default'
                                open={openPrivacy}
                                cancelText="Rechazar"
                                description={(
                                    <>
                                    <p className='popconfirm-text'>
                                        <b>Singular Bank, S.A.U.</b> es responsable del tratamiento de los datos que se recogen en este formulario con la finalidad de ofrecerle más información sobre este producto. Sus datos no serán cedidos, salvo obligación legal, pero en caso de avanzar con su contratación, podrían ser remitidos a la entidad aseguradora, que actuará como responsable en la prestación del servicio. Puede ejercer sus derechos sobre datos personales y trasladar sus dudas en esta materia al DPO, en la dirección lopd@singularbank.es ; asimismo, puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web:  https://www.selfbank.es/politica-de-proteccion-de-datos-para-usuarios.
                                    </p>
                                    <p className='popconfirm-text'>
                                        <b>Información Comunicaciones;</b> Te informamos de que Singular Bank te remitirá comunicaciones comerciales, personalizadas, en función del riesgo y el histórico de operaciones, y asociadas a los productos solicitados o similares a los contratados en nuestra entidad.  Si no quisieras recibir dichas comunicaciones rechaza y en caso contrario acepta.
                                    </p>
                                    </>
                                )}
                            >
                                <Checkbox onChange={changePrivacy} checked={privacyChecked} style={{ fontSize: "15px" }} disabled={buttonLoading} >He leído y acepto la <Link target='_blank' to='https://www.selfbank.es/politica-de-proteccion-de-datos-para-usuarios'>cláusula de privacidad</Link></Checkbox>
                            </Popconfirm>
                        </Form.Item>
                        
                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://cdn.coventia.es/public/Nota+previa+del+mediador+SINGULAR+BANK.+05.02.2024+(004)%5B49%5D.pdf'>términos y condiciones</Link></Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}
                        >
                            <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                                {buttonLoading ? 'Enviando...' : 'Ver mi precio'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <div style={{ display: `${paso === 1 ? 'block' : 'none'}` }}>
                    <ResultParte1 datos={datosForm} priceBasic={priceBasic} pricePremium={pricePremium} onClick={() => setPaso(2)} />
                </div>

                <div style={{ display: `${paso === 2 ? 'block' : 'none'}` }}>
                    <FormularioSingularParte2 datos={datosForm} setDatosForm={setDatosForm} onClick={() => { setPaso(3); }} />
                </div>

                <div style={{ display: `${paso === 3 ? 'block' : 'none'}` }}>
                    <ResultParte2 datos={datosForm} onClick={() => { setPaso(4); }} />
                </div>

                <div style={{ display: `${paso === 4 ? 'block' : 'none'}` }}>
                    <FormularioParte3 datos={datosForm} onClick={() => { setPaso(5); }} />
                </div>

                <div style={{ display: `${paso === 5 ? 'block' : 'none'}` }}>
                    <ResultPaginaPrincipalParte3 datos={datosForm} />
                </div>
            </ConfigProvider>

        </>
    );
}

export default FormPaginaPrincipalSingularParte1;