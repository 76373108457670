const aseguradoraBanco = [
    { "name": "Aseguradora", "type": "Aseguradora" },
    { "name": "Banco-Asegurador", "type": "Banca" },
    { "name": "ABANCA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A", "type": "Banca" },
    { "name": "AEGON ESPAÑA S.A. DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "ALLIANZ POPULAR VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A., SOCIEDAD UNIPERSONAL", "type": "Banca" },
    { "name": "ALLIANZ, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "ASISA VIDA", "type": "Aseguradora" },
    { "name": "ATOCHA, S.A. DE SEGUROS", "type": "Aseguradora" },
    { "name": "AURA, SOCIEDAD ANONIMA DE SEGUROS.", "type": "Aseguradora" },
    { "name": "AXA AURORA VIDA, S.A. DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "AXA SEGUROS GENERALES, S. A. DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "BANKIA MAPFRE VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "BANKINTER SEGUROS DE VIDA, S.A. DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "BANSABADELL VIDA, S.A. DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "BBVASEGUROS, S.A., DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "BILBAO, COMPAÑIA ANONIMA DE SEGUROS Y REASEGUROS.", "type": "Aseguradora" },
    { "name": "CA LIFE INSURANCE EXPERTS COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.U.", "type": "Aseguradora" },
    { "name": "CAIXA ENGINYERS VIDA-CAJA INGENIEROS VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "CAJA GRANADA VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA", "type": "Banca" },
    { "name": "CAJAMAR SEGUROS GENERALES, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "CAJAMAR VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "CAJAMURCIA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "CCM VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA", "type": "Banca" },
    { "name": "CESCE", "type": "Aseguradora" },
    { "name": "CNP PARTNERS DE SEGUROS Y REASEGUROS S.A.", "type": "Aseguradora" },
    { "name": "COMPAÑIA DE SEGUROS IGUALATORIO MEDICO QUIRURGICO Y DE ESPECIALIDADES DE NAVARRA, SOCIEDAD ANONIMA", "type": "Aseguradora" },
    { "name": "DKV SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA ESPAÑOLA", "type": "Aseguradora" },
    { "name": "ERGO VIDA SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA", "type": "Aseguradora" },
    { "name": "Familiar de Seguros Active, SOCIEDAD ANONIMA", "type": "Aseguradora" },
    { "name": "FENIX DIRECTO, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "FIATC, MUTUA DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "GACM SEGUROS GENERALES, COMPAÑÍA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "GENERALI ESPAÑA, SOCIEDAD ANÓNIMA DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "GES, SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "IBERCAJA VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "KUTXABANK ASEGURADORA COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "KUTXABANK VIDA Y PENSIONES COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "LA FE PREVISORA COMPAÑIA DE SEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "LA PREVISION MALLORQUINA DE SEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "LA UNION ALCOYANA, S.A. DE SEGUROS Y REASEGUROS.", "type": "Aseguradora" },
    { "name": "LA UNION MADRILEÑA DE SEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "LIBERBANK VIDA Y PENSIONES, SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "LIBERTY SEGUROS, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "LINEA DIRECTA ASEGURADORA SOCIEDAD ANÓNIMA COMPAÑIA DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "MAPFRE ASISTENCIA, COMPAÑIA INTERNACIONAL DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "MAPFRE ESPAÑA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "MAPFRE VIDA, S.A. DE SEGUROS Y REASEGUROS SOBRE LA VIDA HUMANA", "type": "Aseguradora" },
    { "name": "MARCH VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUR OS", "type": "Banca" },
    { "name": "MARTIERRA SEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "MEDITERRANEO VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "MERIDIANO, S.A., COMPAÑIA ESPAÑOLA DE SEGUROS", "type": "Aseguradora" },
    { "name": "METROPOLIS, S.A., COMPAÑIA NACIONAL DE SEGUROS Y REASEGUROS.", "type": "Aseguradora" },
    { "name": "MGS SEGUROS Y REASEGUROS S.A.", "type": "Aseguradora" },
    { "name": "MUTUA MADRILEÑA AUTOMOVILISTA, SOCIEDAD DE SEGUROS A PRIMA FIJA", "type": "Aseguradora" },
    { "name": "NATIONALE-NEDERLANDEN VIDA, SEGUROS S.A", "type": "Aseguradora" },
    { "name": "NATIONALE-NEDERLANDEN VIDA, SEGUROS S.A / ING BANK NV-Operador de Banca Seguros", "type": "Banca" },
    { "name": "NORTEHISPANA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "OCASO, S.A., COMPAÑIA DE SEGUROS Y REASEGUROS.", "type": "Aseguradora" },
    { "name": "PATRIA HISPANA, S.A. DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "PELAYO VIDA SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "PLUS ULTRA SEGUROS GENERALES Y VIDA, S.A. DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "POLICLINICO, CENTRO MEDICO DE SEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "PREVENTIVA, CIA. DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "REALE SEGUROS GENERALES, S.A.", "type": "Aseguradora" },
    { "name": "REALE VIDA Y PENSIONES, SOCIEDAD ANÓNIMA DE SEGUROS", "type": "Aseguradora" },
    { "name": "RGA RURAL VIDA, S.A. DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "S.O.S. SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "SA NOSTRA COMPAÑÍA DE SEGUROS DE VIDA, S.A.", "type": "Banca" },
    { "name": "SALUS ASISTENCIA SANITARIA, S.A. DE SEGUROS", "type": "Aseguradora" },
    { "name": "SANITAS, SOCIEDAD ANONIMA DE SEGUROS.", "type": "Aseguradora" },
    { "name": "SANTA LUCÍA VIDA Y PENSIONES, S.A. , Compañía de Seguros y Reaseguros", "type": "Aseguradora" },
    { "name": "SANTA LUCIA, S.A. COMPAÑIA DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "SANTANDER MAPFRE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "SANTANDER SEGUROS Y REASEGUROS, COMPAÑIA ASEGURADORA, S.A.", "type": "Banca" },
    { "name": "SANTANDER VIDA SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "SEGURCAIXA ADESLAS, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "SEGUROS CATALANA OCCIDENTE, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS", "type": "Aseguradora" },
    { "name": "SEGUROS EL CORTE INGLES, VIDA, PENSIONES Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "SEGUROS LAGUN ARO VIDA, S.A.", "type": "Banca" },
    { "name": "UNICORP VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Banca" },
    { "name": "UNION DEL DUERO, COMPAÑIA DE SEGUROS DE VIDA, S.A.", "type": "Banca" },
    { "name": "UNION MEDICA LA FUENCISLA, S.A. COMPAÑIA DE SEGUROS.", "type": "Aseguradora" },
    { "name": "VIDACAIXA, SOCIEDAD ANÓNIMA DE SEGUROS Y REASEGUROS", "type": "Banca" },
    { "name": "VITAL SEGURO, S.A.", "type": "Aseguradora" },
    { "name": "ZURICH VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.", "type": "Aseguradora" },
    { "name": "METLIFE EUROPE D.A.C.", "type": "Aseguradora" },
    { "name": "PREVISORA AGRO-INDUSTRIAL, MUTUALITAT DE PREVISIÓ SOCIAL A PRIMA FIXA", "type": "Aseguradora" },
]

export default aseguradoraBanco;