import { useEffect, useState, useRef } from "react";
import changeHeight from "../../../data/changeHeight";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Form, Input, Select, Radio, Space, Button, notification, InputNumber } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import locale_es from 'antd/es/date-picker/locale/es_ES';
import locale_en from 'antd/es/date-picker/locale/en_US';
import banco from "../../../data/aseguradora-banco-caser";
import axios from "axios";
dayjs.extend(customParseFormat);


const FormularioParte2 = ({ datos, setDatosForm, onClick, language }) => {

    const texts = {
        es: {
            p1: "¿Dónde te podemos enviar un email? *",
            rule1: "Por favor, ingresa tu email",
            placeholder1: "tunombre@gmail.com",
            p2: "¿Dónde te podemos llamar? *",
            rule2: "Por favor, ingresa tu teléfono",
            p3: "Nombre *",
            rule3: "Por favor, ingresa tu nombre",
            p4: "Primer apellido *",
            rule4: "Por favor, ingresa tu primer apellido",
            p5: "Segundo apellido *",
            rule5: "Por favor, ingresa tu segundo apellido",
            p6: "Fecha de nacimiento *",
            rule6: "Por favor, ingresa tu fecha de nacimiento",
            p7: "DNI *",
            rule7: "Por favor, ingresa tu DNI",
            p8: "¿Cúantos pagas al año por tu seguro de vida? (€) *",
            rule8: "Por favor, ingresa la cantidad que pagas por tu seguro de vida",
            p9: "¿Fecha en la que quieres que comience tu seguro? *",
            tooltip9: "(Suele coincidir con la fecha de vencimiento de tu seguro actual)",
            rule9: "Por favor, ingresa la fecha en la que quieres que comience tu seguro",
            p10: "¿Cuál es tu suma asegurada? (€) *",
            rule10: "Por favor, ingersa la suma asegurada",
            p11: "Compañía previa *",
            rule11: "Por favor, introduzca la compañia previa",
            placeholder11: "Escribe el nombre de la Aseguradora/Banco",
            p12: "Cobertura *",
            rule12: "Por favor, introduzca la cobertura",
            radio1: "Fallecimiento por cualquier causa",
            radio2: "Fallecimiento e Invalidez por cualquier causa",
            button: "Generar presupuesto",
            button2: "Enviando...",
            button3: "Generando presupuesto...",
            error: "Ha ocurrido un error",
            error_message: "Ha ocurrido un error inesperado, por favor inténtelo más tarde",
            error_minimum: "El importe mínimo en caso de que tu seguro venga de una aseguradora es de 500€"
        },
        en: {
            p1: "Where can we send you an email? *",
            rule1: "Please enter your email",
            placeholder1: "yourname@gmail.com",
            p2: "Where can we call you? *",
            rule2: "Please enter your phone number",
            p3: "Name *",
            rule3: "Please enter your name",
            p4: "First surname *",
            rule4: "Please enter your first surname",
            p5: "Second surname *",
            rule5: "Please enter your second surname",
            p6: "Date of birth *",
            rule6: "Please enter your date of birth",
            p7: "ID / DNI *",
            rule7: "Please enter your ID / DNI",
            p8: "How much do you pay annually for your life insurance? (€) *",
            rule8: "Please enter the amount you pay for your life insurance",
            p9: "What date do you want your insurance to start? *",
            tooltip9: "(Usually coincides with the expiration date of your current insurance)",
            rule9: "Please enter the date you want your insurance to start",
            p10: "What is your insured amount? (€) *",
            rule10: "Please enter the insured amount",
            p11: "Previous company *",
            rule11: "Please enter the previous company",
            placeholder11: "Write the name of the Insurance Company/Bank",
            p12: "Coverage *",
            rule12: "Please enter the coverage",
            radio1: "Death from any cause",
            radio2: "Death and Disability from any cause",
            button: "Generate quote",
            button2: "Sending...",
            button3: "Generating quote...",
            error: "An error has occurred",
            error_message: "An unexpected error has occurred, please try again later",
            error_minimum: "The minimum amount in case your insurance comes from an insurer is €500"
        }
    }

    const refForm2 = useRef(0);
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [sendButtonText, setSendButtonText] = useState(texts[language].button);
    const cambioForm = onClick;
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        changeHeight(refForm2.current.clientHeight);
    })

    useEffect(() => {
        form.setFieldsValue({ email: datos.email, phone: datos.tel });
        setSendButtonText(texts[language].button);
    }, [datos, form])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === refForm2.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(refForm2.current);
    
        return () => {
          resizeObserver.unobserve(refForm2.current);
        };
      }, []);

    const onFinish = async (values) => {

        console.log(values)

        // Tenemos que obtener el type de values.empresa
        const aseguradoraBanco = banco.find((asegBank) => asegBank.name === values.empresa);

        if (aseguradoraBanco.type === "Aseguradora" && values.importe_actual < 500) {
            var agente_data = await axios.get('https://staging.traspasatupoliza.es/api/get_all_data_from_user/d5e11562_bb66_43e3_8f83_1b0a23dda010/' + datos.nombre_agente)
            if (agente_data.data.punto_venta != '0800005') {
                setbuttonLoading(false);
                api.info({
                    message: 'Error',
                    description: texts[language].error_minimum,
                    placement: 'bottom',
                    icon: (
                        <CloseCircleOutlined style={{ color: "#f7151c" }} />
                    )
                });
                return;
            }
        }

        setbuttonLoading(true);
        setSendButtonText(texts[language].button2);
        setTimeout(() => {
            setSendButtonText(texts[language].button3);
        }, 3000);
        values["url"] = datos.url;
        values["procedencia"] = datos.procedencia;
        values["nombre_agente"] = datos.nombre_agente;


        const headers = {
            "Content-Type": "application/json"
        }


        axios.post(process.env.REACT_APP_URL_CALCULO_PRESUPUESTO, values, { headers: headers }).then(function (response) {
            values["presupuesto_id"] = response.data.presupuesto_id
            values["poliza_nueva"] = response.data.poliza_nueva
            values["sumar_asegurada"] = response.data.sumar_asegurada
            values["pago_anual"] = response.data.pago_anual
            values["pago_semestral"] = response.data.pago_semestral
            values["pago_trimestral"] = response.data.pago_trimestral
            values["pago_mensual"] = response.data.pago_mensual
            values["presupuesto_url"] = response.data.presupuesto_url

            setDatosForm(values);
            cambioForm()
            setbuttonLoading(false);
            setSuccess(true);
        })
            .catch(function (error) {
                setbuttonLoading(false);
                setSendButtonText(texts[language].button);
                if (error.response.data.message !== undefined) {
                    openNotification('bottom', error.response.data.message);
                } else {
                    openNotification('bottom', texts[language].error_message);
                }
            });


    };

    const openNotification = (placement, message) => {
        api.info({
            message: texts[language].error,
            description: `${message}`,
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const disabledDateBeforeToday = (current) => {
        // Can not select days before today, today yes
        return current && current <= dayjs().endOf('day');
    };

    const disabledDateInRange = () => {
        const startDate = dayjs().subtract(18, 'year').startOf('day');
        const endDate = dayjs().add(100, 'year').startOf('day');

        return (current) => {
            // Disable dates within the range (inclusive)
            return current && current > startDate && current < endDate;
        };
    };

    return (
        <>
            {contextHolder}
            <div ref={refForm2}>
                <div style={{ padding: '1.5rem' }}>

                <Form
                    form={form}
                    hidden={success}
                    initialValues={{ remember: true }}
                    name="basic2"
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p1}</p>}
                        name="email"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule1 }]}
                    >
                        <Input placeholder={texts[language].placeholder1} type='email' disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p2}</p>}
                        name="phone"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule2 }]}>
                        <Input placeholder="619 99 31 31" type="tel" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p3}</p>}
                        name="name"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule3 }]}>
                        <Input placeholder="Felipe" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p4}</p>}
                        name="last_name"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule4 }]}>
                        <Input placeholder="Gomez" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p5}</p>}
                        name="last_name_2"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule5 }]}>
                        <Input placeholder="Lopez" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p6}</p>}
                        name="birthday"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule6 }]}>
                        <DatePicker 
                            style={{ width: '100%' }} 
                            locale={language == 'es' ? locale_es : locale_en} 
                            disabled={buttonLoading} 
                            format="DD/MM/YYYY" 
                            showToday={false}
                            disabledDate={disabledDateInRange()} 
                            defaultPickerValue={dayjs().subtract(18, 'year').endOf('day')} 
                        />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p7}</p>}
                        name="dni_texto"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule7 }]}>
                        <Input placeholder="12345678A" type="text" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p8}</p>}
                        name="importe_actual"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule8 }]}>
                        <Input placeholder="700" type="number" disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p9}</p>}
                        name="fin_seguro"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule9 }]}
                        tooltip={{ title: texts[language].tooltip9 }}>
                        <DatePicker disabledDate={disabledDateBeforeToday} style={{ width: '100%' }} locale={language == 'es' ? locale_es : locale_en} disabled={buttonLoading} format="DD/MM/YYYY" />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p10}</p>}
                        name="suma_asegurada_introducida"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule10 }]}>
                        <InputNumber placeholder="10000" disabled={buttonLoading} max={600000} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p11}</p>}
                        name="empresa"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule11 }]}>
                        <Select showSearch placeholder={texts[language].placeholder11} disabled={buttonLoading}>
                            {banco.map((asegBank) => (
                                <Select.Option key={asegBank.name} value={asegBank.name}> {asegBank.name} </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p12}</p>}
                        name="cobertura"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: texts[language].rule12 }]}>
                        <Radio.Group onChange={onChange} value={value} disabled={buttonLoading}>
                            <Space direction="vertical" style={{ marginLeft: '1rem' }}>
                                <Radio value={"Fallecimiento por cualquier causa"}>{texts[language].radio1}</Radio>
                                <Radio value={"Fallecimiento e Invalidez por cualquier causa"}>{texts[language].radio2}</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0.6rem" }}>
                        <Button style={{ width: "100%", height: "35px", marginTop:"0.8rem" }} type='primary' loading={buttonLoading} htmlType="submit">
                            {sendButtonText}
                        </Button>
                    </Form.Item>
                </Form>


                </div>
            </div>


        </>
    );

}

export default FormularioParte2;