function changeHeight(height) {

    let data = {
        type: 'form-child',
        height: height
    }
    const ventana = window.parent;
    ventana.postMessage(JSON.stringify(data), '*');
}

export default changeHeight;