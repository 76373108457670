const paises = [
    "España",
    "Aruba",
    "Afganistán",
    "Angola",
    "Anguila",
    "Åland",
    "Albania",
    "Andorra",
    "Antillas Neerlandesas",
    "Emiratos Árabes Unidos",
    "Argentina",
    "Armenia",
    "Samoa Americana",
    "Antártida",
    "Territorios Australes Franceses",
    "Antigua y Barbuda",
    "Australia",
    "Austria",
    "Azerbaiyán",
    "Burundi",
    "Bélgica",
    "Benín",
    "Burkina Faso",
    "Bangladesh",
    "Bulgaria",
    "Bahréin",
    "Bahamas",
    "Bosnia y Herzegovina",
    "Bielorrusia",
    "Belice",
    "Bermudas",
    "Bolivia",
    "Brasil",
    "Barbados",
    "Brunéi",
    "Bután",
    "Isla Bouvet",
    "Botsuana",
    "República Centroafricana",
    "Canadá",
    "Islas Cocos",
    "Suiza",
    "Chile",
    "China",
    "Costa de Marfil",
    "Camerún",
    "República Democrática del Congo",
    "República del Congo",
    "Islas Cook",
    "Colombia",
    "Comoras",
    "Cabo Verde",
    "Costa Rica",
    "Cuba",
    "Isla de Navidad",
    "Islas Caimán",
    "Chipre",
    "República Checa",
    "Alemania",
    "Yibuti",
    "Dominica",
    "Dinamarca",
    "República Dominicana",
    "Argelia",
    "Ecuador",
    "Egipto",
    "Eritrea",
    "Sahara Occidental",
    "Estonia",
    "Etiopía",
    "Finlandia",
    "Fiyi",
    "Islas Malvinas",
    "Francia",
    "Islas Feroe",
    "Micronesia",
    "Gabón",
    "Reino Unido",
    "Georgia",
    "Guernsey",
    "Ghana",
    "Gibraltar",
    "Guinea",
    "Guadalupe",
    "Gambia",
    "Guinea-Bissau",
    "Guinea Ecuatorial",
    "Grecia",
    "Granada",
    "Groenlandia",
    "Guatemala",
    "Guayana Francesa",
    "Guam",
    "Guyana",
    "Hong Kong",
    "Islas Heard y McDonald",
    "Honduras",
    "Croacia",
    "Haití",
    "Hungría",
    "Indonesia",
    "Isla de Man",
    "India",
    "Territorio Británico del Océano Índico",
    "Irlanda",
    "Irán",
    "Iraq",
    "Islandia",
    "Israel",
    "Italia",
    "Jamaica",
    "Jersey",
    "Jordania",
    "Japón",
    "Kazajistán",
    "Kenia",
    "Kirguistán",
    "Camboya",
    "Kiribati",
    "San Cristóbal y Nieves",
    "Corea del Sur",
    "Kuwait",
    "Laos",
    "Líbano",
    "Liberia",
    "Libia",
    "Santa Lucía",
    "Liechtenstein",
    "Sri Lanka",
    "Lesoto",
    "Lituania",
    "Luxemburgo",
    "Letonia",
    "Macao",
    "Marruecos",
    "Mónaco",
    "Moldavia",
    "Madagascar",
    "Maldivas",
    "México",
    "Islas Marshall",
    "Macedonia",
    "Malí",
    "Malta",
    "Birmania",
    "Montenegro",
    "Mongolia",
    "Islas Marianas del Norte",
    "Mozambique",
    "Mauritania",
    "Montserrat",
    "Martinica",
    "Mauricio",
    "Malaui",
    "Malasia",
    "Mayotte",
    "Namibia",
    "Nueva Caledonia",
    "Níger",
    "Norfolk",
    "Nigeria",
    "Nicaragua",
    "Niue",
    "Países Bajos",
    "Noruega",
    "Nepal",
    "Nauru",
    "Nueva Zelanda",
    "Omán",
    "Pakistán",
    "Panamá",
    "Islas Pitcairn",
    "Perú",
    "Filipinas",
    "Palaos",
    "Papúa Nueva Guinea",
    "Polonia",
    "Portugal TT",
    "Puerto Rico",
    "Corea del Norte",
    "Portugal",
    "Paraguay",
    "Territorios palestinos",
    "Polinesia Francesa",
    "Qatar",
    "Reunión",
    "Rumania",
    "Rusia",
    "Ruanda",
    "Arabia Saudita",
    "Sudán",
    "Senegal",
    "Singapur",
    "Islas Georgias del Sur y Sandwich del Sur",
    "Santa Helena",
    "Svalbard y Jan Mayen",
    "Islas Salomón",
    "Sierra Leona",
    "El Salvador",
    "San Marino",
    "Somalia",
    "San Pedro y Miquelón",
    "Serbia",
    "Santo Tomé y Príncipe",
    "Surinam",
    "Eslovaquia",
    "Eslovenia",
    "Suecia",
    "Suazilandia",
    "Seychelles",
    "Siria",
    "Islas Turcas y Caicos",
    "Chad",
    "Togo",
    "Tailandia",
    "Tayikistán",
    "Tokelau",
    "Turkmenistán",
    "Timor Oriental",
    "Tonga",
    "Trinidad y Tobago",
    "Túnez",
    "Turquía",
    "Tuvalu",
    "República de China",
    "Tanzania",
    "Uganda",
    "Ucrania",
    "Islas ultramarinas de Estados Unidos",
    "Uruguay",
    "Estados Unidos",
    "Uzbekistán",
    "Ciudad del Vaticano",
    "San Vicente y las Granadinas",
    "Venezuela",
    "Islas Vírgenes Británicas",
    "Islas Vírgenes Estadounidenses",
    "Vietnam",
    "Vanuatu",
    "Wallis y Futuna",
    "Samoa",
    "Yemen",
    "Sudáfrica",
    "Zambia",
    "Zimbabue"
];

export default paises;