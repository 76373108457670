import { useEffect, useState, useRef } from "react";
import changeHeight from "../../../data/changeHeight";


const ResultPaginaPrincipalParte3 = ({ datos, language }) => {

    const texts = {
        es: {
            gracias: "¡Muchas gracias",
            contrato: "En breve te llegará el contrato con la solicitud de seguro para que puedas firmarlo"
        },
        en: {
            gracias: "Thank you",
            contrato: "You will soon receive the contract with the insurance request so you can sign it"
        }
    }

    const refResult3 = useRef(0);
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        changeHeight(refResult3.current.clientHeight)
    })

    useEffect(() => {
        setNombre(datos.name);
    }, [datos])

    return (
        <>
            <div ref={refResult3}>
                <div style={{ padding: '2rem' }}>
                    <p>{texts[language].gracias} {nombre}! {texts[language].contrato}</p>
                </div>
            </div>
        </>
    )

}

export default ResultPaginaPrincipalParte3;