import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Alert } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/*
 * Componente para gestionar los beneficiarios en los dinstintos formularios de coventia-forms-frontend.
 * 
 * Props:
 * - form: Instancia del formulario AntD proporcionada por useForm(). (Mirar en el componente <Form></Form> de AntD)
 * - designado: Estado que determina si se muestran los beneficiarios (Establecer la visibilidad del componente).
 * - buttonLoading: Booleano que indica si el botón de enviar está en estado de carga. (Deshabilita los campos si es true).
 * - setCanSubmit: Función para actualizar el estado de la capacidad de enviar el formulario en el componente principal. (Habilita o deshabilita el botón de enviar de el Form padre).
 * - language: Idioma del formulario. (Para mostrar mensajes de alerta en el idioma correcto).
 * 
 * Funcionalidad:
 * - Gestiona la entrada de datos de los beneficiarios incluyendo nombre completo, DNI y porcentaje.
 * - Realiza validaciones dinámicas para asegurar que la suma de los porcentajes de los beneficiarios sea exactamente 100%.
 * - Muestra una alerta si la suma de los porcentajes no es 100% y deshabilita el envío del formulario hasta que se corrija.
 */


const BeneficiariosFormComponent = ({ form, designado, buttonLoading, setCanSubmit, language }) => {
    const [isTotalPercentageError, setIsTotalPercentageError] = useState(false);

    const texts = {
        es: {
            title: "Personas designadas *",
            tooltip: "Hay que indicar el nombre completo, DNI y porcentaje. El porcentaje total debe ser del 100%",
            warningTitle: "¡Importante!",
            warningDescription: "La suma de los porcentajes de todos los beneficiarios debe ser exactamente 100%.",
            addBeneficiary: "Añadir beneficiario",
            message: "Por favor, indica a las personas designadas como se indica en la pista",
            beneficiary: "Beneficiario",
            name: "Nombre completo",
            dni: "DNI",
            percentage: "Porcentaje",
            nameRules: "Nombre completo es requerido",
            dniRules: "DNI es requerido",
            percentageRules: "Porcentaje es requerido"
        },
        en: {
            title: "Designated persons *",
            tooltip: "You must indicate the full name, ID and percentage. The total percentage must be 100%",
            warningTitle: "Important!",
            warningDescription: "The sum of the percentages of all beneficiaries must be exactly 100%.",
            addBeneficiary: "Add beneficiary",
            message: "Please indicate the designated persons as indicated in the hint",
            beneficiary: "Beneficiary",
            name: "Full name",
            dni: "DNI / ID",
            percentage: "Percentage",
            nameRules: "Full name is required",
            dniRules: "DNI / ID is required",
            percentageRules: "Percentage is required"
        }
    }

    // Calcula la suma de los porcentajes para validar que sea 100%.
    const handlePercentageChange = () => {
        const newBeneficiarios = form.getFieldValue('beneficiarios') || [];
        const totalPercentage = newBeneficiarios.reduce((acc, curr) => acc + Number(curr?.porcentaje || 0), 0);
        setIsTotalPercentageError(designado === "Personas designadas" && totalPercentage !== 100);
        setCanSubmit(totalPercentage === 100);
    };

    // Efecto para manejar cambios en los beneficiarios y actualizar la validación de porcentajes.
    useEffect(() => {
        if (designado === "Personas designadas") {
            handlePercentageChange();
        }
        if (designado === "Cláusula estándar") {
            setCanSubmit(true);
        }
    }, [form.getFieldValue('beneficiarios'), designado]); // eslint-disable-line

    return (
        <Form.Item
            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].title}</p>}
            tooltip={{ title: texts[language].tooltip }}
            style={{ display: `${designado === "Personas designadas" ? 'block' : 'none'}`, marginBottom: "0.6rem" }}
        >
            <Form.List
                name="beneficiarios"
                rules={[{ required: designado === "Personas designadas", message: texts[language].message }]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Card key={key} title={`${texts[language].beneficiary} ${index + 1}`} style={{ marginBottom: 16, backgroundColor: 'rgba(0, 0, 0, 0.02)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red', marginBottom: 16 }} />
                                <Form.Item
                                    {...restField}
                                    label={texts[language].name}
                                    name={[name, 'nombreCompleto']}
                                    rules={[{ required: true, message: texts[language].nameRules }]}
                                >
                                    <Input disabled={buttonLoading} />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label={texts[language].dni}
                                    name={[name, 'dni']}
                                    rules={[{ required: true, message: texts[language].dniRules }]}
                                >
                                    <Input disabled={buttonLoading} />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label={texts[language].percentage}
                                    name={[name, 'porcentaje']}
                                    rules={[{ required: true, message: texts[language].percentageRules }]}
                                >
                                    <Input
                                        disabled={buttonLoading}
                                        onChange={handlePercentageChange}
                                    />
                                </Form.Item>
                            </Card>
                        ))}
                        {fields.length < 6 && (
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {texts[language].addBeneficiary}
                                </Button>
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>
            {isTotalPercentageError && (
                <Alert
                    message={texts[language].warningTitle}
                    description={texts[language].warningDescription}
                    type="warning"
                    showIcon
                />
            )}
        </Form.Item>
    );
};

export default BeneficiariosFormComponent;
