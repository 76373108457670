import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Checkbox, ConfigProvider, Result, InputNumber, Select, DatePicker, notification } from 'antd';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import changeHeight from '../data/changeHeight';

const FormRevolving = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true);
        form.setFieldsValue({ duracion_seguro: 0 });
        axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
            .then(function (response) {
                if (response.data.message === "OK") {
                    setbuttonLoading(false);
                    setSuccess(true);
                    setResult("block");
                    changeHeight(heightResult);
                }
            })
            .catch(function () {
                openNotification('bottom');
                setbuttonLoading(false);
            });
    };

    const disabledDateBeforeToday = (current) => {
        // Can not select days before today, today yes
        return current && current > dayjs().endOf('day');
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const subtitleText = "Tu información ha sido enviada correctamente.<br/>En breve nos pondremos en contacto contigo, pero recuerda que puedes concertar una cita haciendo clic en el siguiente botón.";

    return (

        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Form
                        form={form}
                        style={{ padding: '1rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                    >

                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Con qué banco / entidad hiciste la tarjeta?</p>}
                            name="compania"
                            rules={[{ required: true, message: 'Por favor, escoge el banco' }]}
                        >
                            <Select showSearch placeholder='Escoge tu banco o aseguradora' disabled={buttonLoading}>
                                <Select.Option key="No lo encuentro en la lista" value="No lo encuentro en la lista">No lo encuentro en la lista</Select.Option>
                                <Select.Option key="Banco Popular" value="Banco Popular">Banco Popular</Select.Option>
                                <Select.Option key="BBVASEGUROS, S.A., DE SEGUROS Y REASEGUROS" value="BBVASEGUROS, S.A., DE SEGUROS Y REASEGUROS">BBVA</Select.Option>
                                <Select.Option key="CAJAMAR VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS" value="CAJAMAR VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS">Cajamar</Select.Option>
                                <Select.Option key="Caixanova (NCG)" value="Caixanova (NCG)">Caixanova (NCG)</Select.Option>
                                <Select.Option key="BANSABADELL VIDA, S.A. DE SEGUROS Y REASEGUROS" value="BANSABADELL VIDA, S.A. DE SEGUROS Y REASEGUROS">Sabadell</Select.Option>
                                <Select.Option key="SANTANDER SEGUROS Y REASEGUROS, COMPAÑIA ASEGURADORA, S.A." value="SANTANDER SEGUROS Y REASEGUROS, COMPAÑIA ASEGURADORA, S.A.">Santander</Select.Option>
                                <Select.Option key="La Caixa / Caixabank" value="La Caixa / Caixabank">La Caixa / Caixabank</Select.Option>
                                <Select.Option key="Caja España / Caja Duero" value="Caja España / Caja Duero">Caja España / Caja Duero</Select.Option>
                                <Select.Option key="Barclays" value="Barclays">Barclays</Select.Option>
                                <Select.Option key="Cetelem" value="Cetelem">Cetelem</Select.Option>
                                <Select.Option key="Alcampo" value="Alcampo">Alcampo</Select.Option>
                                <Select.Option key="El Corte Inglés" value="El Corte Inglés">El Corte Inglés</Select.Option>
                                <Select.Option key="Carrefour" value="Carrefour">Carrefour</Select.Option>
                                <Select.Option key="Bankinter" value="Bankinter">Bankinter</Select.Option>
                                <Select.Option key="EVO Banco" value="EVO Banco">EVO Banco</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuándo contrataste la tarjeta?</p>}
                            name="fecha_pago"
                            rules={[{ required: true, message: 'Por favor, ingresa una fecha' }]}
                        >
                            <DatePicker picker='month' placeholder='mm/yyyy' style={{ width: '100%' }} disabledDate={disabledDateBeforeToday} locale={locale} disabled={buttonLoading} format="MM/YYYY" />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Qué cantidad de dinero te concedieron en la tarjeta?</p>}
                            name="prima_unica"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa un valor' }]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder='240000' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es la duración de la hipoteca?</p>}
                            name="duracion_seguro"
                        >
                            <InputNumber style={{ width: '100%' }} addonAfter="años" placeholder='40' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email?</p>}
                            name="email"
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@email.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde podemos llamarte?</p>}
                            name="tel"
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input placeholder='919 99 31 31' type='tel' disabled={buttonLoading} maxLength={9} minLength={9} />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                        </Form.Item>


                        <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                            {buttonLoading ? 'Enviando...' : 'Enviar'}
                        </Button>

                    </Form>
                </div>

                <div ref={refResult}>
                    <div style={{ display: `${result}` }}>
                        <Result
                            icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                            style={{ padding: '1.5rem', paddingTop: '3rem' }}
                            status="success"
                            title="¡Gracias!"
                            subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}
                            extra={[
                                <Button type="primary" key="console" target='_blank' href='https://meetings-eu1.hubspot.com/meetings/francisco-cano-fernandez'>
                                    Haz clic para concertar una cita
                                </Button>
                            ]}
                        />
                    </div>
                </div>
            </ConfigProvider >

        </>
    );
}

export default FormRevolving;