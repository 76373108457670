import React from 'react';
import { Form, Input, InputNumber, Checkbox, Button, ConfigProvider } from 'antd';
import { Link } from 'react-router-dom';

const FormParte1 = ({ form, onFinishParte1, buttonLoading, primaryColor }) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primaryColor,
                },
            }}
        >
            <style>
                {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
            <Form
                form={form}
                style={{ padding: '1.5rem' }}
                name="parte1"
                initialValues={{ remember: true }}
                layout='vertical'
                onFinish={onFinishParte1}
                requiredMark={false}
            >
                <Form.Item
                    hidden={true}
                    label="URL"
                    name="url"
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Edad actual *</p>}
                    name="age"
                    style={{ marginBottom: "0.6rem" }}
                    colon={false}
                    rules={[
                        { required: true, message: 'Por favor, ingresa tu edad' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value >= 18) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Tienes que ser mayor de edad'));
                            },
                        }),
                    ]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='50' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Importe del seguro anual (€) *</p>}
                    name="anual_policy"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Indica el importe de su seguro anual' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('La cantidad tienen que ser mayor a 0'));
                        },
                    }),
                    ]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='1000' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Capital pendiente (€) *</p>}
                    name="pending_capital"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Indica su capital pendiente' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('La cantidad tienen que ser mayor a 0'));
                        },
                    }),]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='30000' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuántos años le quedan de préstamo? *</p>}
                    name="years_remaining"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Por favor, ingresa los años que le quedan de préstamo' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Los años tienen que ser mayor a 0'));
                        },
                    }),]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='20' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                    <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                </Form.Item>

                <Form.Item
                    style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}
                >
                    <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                        {buttonLoading ? 'Enviando...' : 'Siguiente'}
                    </Button>
                </Form.Item>
            </Form>
        </ConfigProvider >
    );
}

export default FormParte1;
