import { useEffect, useRef, useState } from "react";
import { Button, Col, Card, Row, Layout } from "antd";
import changeHeight from "../../../data/changeHeight";
import { Content } from "antd/es/layout/layout";

const ResultPaginaPrincipalParte1 = ({ datos, onClick, pricePremium, priceBasic }) => {
    const refResult = useRef(0);
    const [descuento, setDescuento] = useState(0);
    const [noAseguradora, setNoAseguradora] = useState(false);

    useEffect(() => {
        if (datos.procedencia === "NoSeguro") {
            setNoAseguradora(true);
        } else {
            let descuento;
            if (datos.procedencia === "Banco") {
                descuento = datos.importe * 0.7
            } else {
                descuento = datos.importe * 0.85
            }
            if (!Number.isInteger(descuento)) {
                descuento = descuento.toFixed(2);
            }
            setDescuento(descuento);
        }

    }, [datos.procedencia, datos.importe])

    // Observer para redimensionar
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refResult.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refResult.current);

        return () => {
            resizeObserver.unobserve(refResult.current);
        };
    }, []);

    return (
        <>
            <div ref={refResult}>
                {noAseguradora ? (
                    // Renderizar contenido cuando noAseguradora sea true

                    <Layout style={{ backgroundColor: 'white', paddingBottom: '20px' }} >
                        <Content style={{ marginLeft: 20, marginRight: 20 }}>
                            <h1>¡Calculo realizado!</h1>
                            <p><b>Esto es lo que pagarás de forma anual por su seguro de vida</b></p>
                            <Row justify={'center'} align={"middle"} gutter={[40, 20]} style={{ marginTop: 40, marginBottom: 40 }}>
                                <Col xs={24} md={20} lg={10} xl={8}>
                                    <Card>
                                        <Row align={'middle'} justify={'center'}>
                                            <Col>
                                                <h1 style={{ marginBottom: -5 }}>{priceBasic}€</h1>
                                            </Col>
                                        </Row>
                                        <Row align={'middle'} justify={'center'}>
                                            <Col>
                                                <h2 style={{ fontFamily: '"Roboto",sans-serif' }}>Fallecimiento</h2>
                                                <span style={{ fontFamily: '"Roboto",sans-serif', color: '#a2a5b9' }}>Por cualquier causa</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} md={20} lg={10} xl={8}>
                                    <Card>
                                        <Row align={'middle'} justify={'center'}>
                                            <Col>
                                                <h1 style={{ marginBottom: -5 }}>{pricePremium}€</h1>
                                            </Col>
                                        </Row>
                                        <Row align={'middle'} justify={'center'}>
                                            <Col>
                                                <h2 style={{ fontFamily: '"Roboto",sans-serif' }}>Fallecimiento + IPA</h2>
                                                <span style={{ fontFamily: '"Roboto",sans-serif', color: '#a2a5b9' }}>Incapacidad Permanente Absoluta</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={24}>
                                    <Button type="primary" style={{ width: '100%' }} key="console" href='tel:+34919993131'>
                                        Llamar a Coventia
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={24}>
                                    <Button type="primary" style={{ width: '100%' }} key="console" target='_blank' href='https://meetings-eu1.hubspot.com/santiago-gatti'>
                                        Concertar una cita
                                    </Button>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>

                ) : (
                    // Renderizar contenido cuando noAseguradora sea true
                    <div style={{ padding: '2rem' }}>
                        <p>El importe de la póliza con Coventia sería de {descuento}€ anual.
                            Puedes continuar con el formulario para obtener un presupuesto sin ningún compromiso.</p>
                        <Button style={{ width: "100%", height: "35px" }} type='primary' onClick={onClick}>Generar presupuesto</Button>
                    </div>
                )}
            </div>
        </>


    );

}

export default ResultPaginaPrincipalParte1;