import { Button, Divider } from "antd";
import changeHeight from "../../../data/changeHeight";
import { useEffect, useRef, useState } from "react";

const ResultPaginaPrincipalParte2 = ({ datos, onClick, language }) => {

    const texts = {
        es: {
            gracias: "¡Muchas gracias",
            descarga: "Puedes descargar el presupuesto desde aquí:",
            descargaButton: "Descargar prespuesto",
            segundos: "Estás a tan solo 30 segundos de conseguir el ahorro en tu póliza",
            solicitud: "Realizar una solicitud"
        },
        en: {
            gracias: "Thank you",
            descarga: "You can download the budget (it's in Spanish) from here:",
            descargaButton: "Download budget",
            segundos: "You are just 30 seconds away from getting the savings on your policy",
            solicitud: "Make a request"
        }
    }

    const refResult2 = useRef(0);
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        changeHeight(refResult2.current.clientHeight)
    })

    useEffect(() => {
        setNombre(datos.name)
    }, [datos])




    return (
        <>
            <div ref={refResult2}>
                <div style={{ padding: '2rem' }}>
                    <p>{texts[language].gracias} {nombre}!</p>
                    <p>{texts[language].descarga}</p>
                    <Button block ghost type="primary" target="_blank" href={datos.presupuesto_url} >{texts[language].descargaButton}</Button>
                    <Divider />
                    <p>{texts[language].segundos}</p>
                    <Button block type='primary' onClick={onClick}>{texts[language].solicitud}</Button>
                </div>
            </div>
        </>
    );

}

export default ResultPaginaPrincipalParte2;