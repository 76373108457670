import React from 'react';
import { Form, Input, InputNumber, Checkbox, Button, ConfigProvider, Select } from 'antd';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const FormParte1 = ({ form, onFinishParte1, buttonLoading, primaryColor }) => {

    const customParser = (value) => {
        // Reemplaza comas por puntos
        const processedValue = value.replace(/,/g, '.');
      
        // Analiza el valor en un número de punto flotante
        const parsedValue = parseFloat(processedValue);
      
        // Asegúrate de que el resultado es un número
        if (!isNaN(parsedValue)) {
          return parsedValue;
        }
      
        return undefined; // Si no se pudo analizar, devuelve undefined
      };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primaryColor,
                },
            }}
        >
            <style>
                {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
            <Form
                form={form}
                style={{ padding: '1.5rem' }}
                name="parte1"
                initialValues={{ remember: true }}
                layout='vertical'
                onFinish={onFinishParte1}
                requiredMark={false}
            >
                <Form.Item
                    hidden={true}
                    label="URL"
                    name="url"
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Mes *</p>}
                    name="mes"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Selecciona un mes' }]}
                >
                    <Select style={{ width: '100%' }} placeholder='Selecciona un mes' disabled={buttonLoading}>
                        <Option value={1}>Enero</Option>
                        <Option value={2}>Febrero</Option>
                        <Option value={3}>Marzo</Option>
                        <Option value={4}>Abril</Option>
                        <Option value={5}>Mayo</Option>
                        <Option value={6}>Junio</Option>
                        <Option value={7}>Julio</Option>
                        <Option value={8}>Agosto</Option>
                        <Option value={9}>Septiembre</Option>
                        <Option value={10}>Octubre</Option>
                        <Option value={11}>Noviembre</Option>
                        <Option value={12}>Diciembre</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Capital pendiente (€) *</p>}
                    name="capital_pendiente"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Indica su capital pendiente' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('La cantidad tienen que ser mayor a 0'));
                        },
                    }),]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='30000' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuántos años le quedan de préstamo? *</p>}
                    name="anos_pendientes"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Por favor, ingresa los años que le quedan de préstamo' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Los años tienen que ser mayor a 0'));
                        },
                    }),]}
                >
                    <InputNumber style={{ width: '100%' }} placeholder='20' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Diferencial *</p>}
                        name="diferencial"
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Indica el valor del diferencial' }]}
                        tooltip={{ title: 'El diferencial es el porcentaje que tu banco suma al euribor para determinar el interés de la hipoteca', icon: <InfoCircleOutlined /> }}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder='0.3' step={0.01} disabled={buttonLoading} parser={customParser} />
                    </Form.Item>

                

                <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                    <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                </Form.Item>

                <Form.Item
                    style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}
                >
                    <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                        {buttonLoading ? 'Enviando...' : 'Siguiente'}
                    </Button>
                </Form.Item>
            </Form>
        </ConfigProvider >
    );
}

export default FormParte1;
