


import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Card, notification, ConfigProvider, Result, Alert, Checkbox, InputNumber, DatePicker } from 'antd';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import changeHeight from '../data/changeHeight';

const FormComplementoPaternidad = () => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [alertAnoJubilacion, setAlertAnoJubilacion] = useState(false);
    const [alertHijos, setAlertHijos] = useState(false);
    const [alertTipoJubilacion, setAlertTipoJubilacion] = useState(false);
    const [typeOfAlert, setTypeOfAlert] = useState("warning");
    const [textAlert, setTextAlert] = useState("");
    const [cantidadHijos, setCantidadHijos] = useState(0);
    const [complementoPaternidadFile, setComplementoPaternidadFile] = useState(null);
    const [anoJubilacion, setAnoJubilacion] = useState(null);


    const onChangeHijos = (value) => {
        if (value === 0 || value === 1) {
            setTextAlert("Lamentablemente, para reclamar el complemento de paternidad, es necesario tener al menos dos hijos");
            setTypeOfAlert("error");
            setAlertHijos(true);
        } else if (value > 4) {
            setTextAlert("Con cuatro hijos, se recibe el máximo pago del complemento de paternidad, más no incrementa la cantidad recibida.")
            setTypeOfAlert("info");
            setAlertHijos(true);
            setCantidadHijos(value);
        }
        else {
            setAlertHijos(false);
            setCantidadHijos(value);
        }
    }

    const onChangeTipoJubilacion = (value) => {
        //Si la jubilacion es anticipada, mostrar alerta
        if (value === "Anticipada") {
            setAlertTipoJubilacion(true);
        } else {
            setAlertTipoJubilacion(false);
        }
    }

    const onChangeAnoJubilacion = (value) => {
        const selectedDate = dayjs(value);
        const startDate = dayjs('2016-01-01');
        const endDate = dayjs('2021-02-03');
    
        if (selectedDate.isBefore(startDate) || selectedDate.isAfter(endDate)) {
            setAlertAnoJubilacion(true);
            form.setFieldsValue({ fechaJubilacion: null });
        } else {
            setAlertAnoJubilacion(false);
            form.setFieldsValue({ fechaJubilacion: selectedDate });
        }
    };

    const disabledDate = (current) => {
        if (!anoJubilacion) return true;

        const anoJubilacionNumber = parseInt(anoJubilacion.split(" ")[0]);

        if (anoJubilacion === "2021 (antes del 3 de febrero)") {
            return current && (current > dayjs('2021-02-03') || current.year() !== 2021);
        }

        return current && (current.year() !== anoJubilacionNumber);
    };

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = async (values) => {

        const hijos = [];
        for (let i = 1; i <= cantidadHijos; i++) {
            hijos.push({
                "NombreHijo": values[`nombreHijo${i}`],
                "FechaNacimientoHijo": dayjs(values[`fechaNacimientoHijo${i}`]).format('YYYY-MM-DD'),
                "DniHijo": values[`dniHijo${i}`]
            });
        }

        const formData = {
            "formData": {
                "anoJubilacion": values.anoJubilacion,
                "cantidadJubilacion": values.cantidadJubilacion,
                "codigoPostal": values.codigoPostal,
                "direccion": values.direccion,
                "dni": values.dni,
                "dniConyuge": values.dniConyuge,
                "email": values.email,
                "fechaJubilacion": dayjs(values.fechaJubilacion).format('YYYY-MM-DD'),
                "cantidadHijos": cantidadHijos,
                "nombre": values.nombre,
                "nombreConyuge": values.nombreConyuge,
                "provincia": values.provincia,
                "tel": values.tel,
                "terms": values.terms,
                "tipoJubilacion": values.tipoJubilacion,
                "hijos": hijos,
            }
        };

        setButtonLoading(true);
        try {
            const response = await axios.post('https://fhfjx4di7l5rx67ft4ztrwzrre0sxokv.lambda-url.eu-west-3.on.aws/', formData, {
                responseType: 'arraybuffer'
            });

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                // Crear una URL para el Blob
                const url = URL.createObjectURL(blob);

                // Guardar la URL del Blob en el estado del componente
                setComplementoPaternidadFile(url);
                setButtonLoading(false);
                setSuccess(true);
                setResult("block");
                setHeightResult(refResult.current.clientHeight);
            }
        } catch (error) {
            console.log(error);
            setButtonLoading(false);
            openNotification('topRight');
        }
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description: 'Ha ocurrido un error inesperado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const disabledDateBeforeToday = (current) => {
        return current && current > dayjs().endOf('day');

    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Card
                        title="Formulario para rellenar su documento del complemento de paternidad"
                        style={{ width: '100%', backgroundColor: "#f0f2f5" }}
                        bordered={false}
                        headStyle={{
                            backgroundColor: primaryColor,
                            color: "white"
                        }}
                    >
                        <div>
                            <Form
                                form={form}
                                style={{ padding: '1rem' }}
                                hidden={success}
                                name="basic"
                                initialValues={{ remember: true }}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="URL"
                                    name="url"
                                >
                                    <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="FB"
                                    name="fbp">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="Hubspotutk"
                                    name="hubspotutk">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Qué tipo de jubilación obtuviste?</p>}
                                    name="tipoJubilacion"
                                    rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                                >
                                    <Select showSearch placeholder='Escoge una opción' disabled={buttonLoading} onChange={onChangeTipoJubilacion}>
                                        <Select.Option key="Ordinaria" value="Ordinaria">Jubilación ordinaria</Select.Option>
                                        <Select.Option key="Anticipada" value="Anticipada">Jubilación anticipada</Select.Option>
                                        <Select.Option key="Otra" value="Otra">Otra</Select.Option>
                                    </Select>
                                </Form.Item>
                                {/* Alert tipo de jubilacion */}
                                <div style={{ display: alertTipoJubilacion ? "block" : "none" }}>
                                    <Alert style={{ marginTop: "1%", marginBottom: "1%" }} message="La jubilación anticipada reduce las posibilidades de recibir el complemento de paternidad." type="info" />
                                </div>

                                {/* Fecha jubilación */}
                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿A qué fecha te jubilaste?</p>}
                                    name="fechaJubilacion"
                                    rules={[{ required: true, message: 'Por favor, introduce la fecha en la que te jubilaste' }]}
                                >
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        locale={locale}
                                        disabled={buttonLoading}
                                        format={'DD/MM/YYYY'}
                                        placeholder="Introduce la fecha en la que te jubilaste"
                                        onChange={onChangeAnoJubilacion}
                                        defaultPickerValue={anoJubilacion ? dayjs(`${parseInt(anoJubilacion.split(" ")[0])}-01-01`) : null}
                                    />
                                </Form.Item>

                                <div style={{ display: `${alertAnoJubilacion ? "block" : "none"}` }}>
                                    <Alert style={{ marginTop: "1%", marginBottom: "1%" }} message="Si no te jubilaste entre el 1 de enero de 2016 y el 3 de febrero de 2021, lamentablemente, no eres elegible para reclamar el complemento de paternidad." type="error" />
                                </div>

                                {/* Cantidad jubilacion */}
                                <Form.Item
                                    style={{ marginBottom: "0.6rem", width: "100%" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuánto percibes de jubilación?</p>}
                                    name="cantidadJubilacion"
                                    rules={[{ required: true, message: 'Por favor, introduce la cantidad que cobras de jubilación' }]}
                                >
                                    <InputNumber style={{ width: "100%" }} min={0} max={10000} disabled={buttonLoading} precision={2} decimalSeparator=',' />
                                </Form.Item>


                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email?</p>}
                                    name="email"
                                    rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                                >
                                    <Input placeholder='tunombre@email.com' type='email' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde podemos llamarte?</p>}
                                    name="tel"
                                    rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                                >
                                    <Input placeholder='919993131' type='tel' disabled={buttonLoading} maxLength={9} minLength={9} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es tu DNI?</p>}
                                    name="dni"
                                    rules={[{ required: true, message: 'Por favor, introduce tu DNI' }]}
                                >
                                    <Input disabled={buttonLoading} placeholder="Introduce tu DNI" />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es el DNI de tu cónyuge?</p>}
                                    name="dniConyuge"
                                    rules={[{ required: true, message: 'Por favor, introduce el DNI de su cónyuge' }]}
                                >
                                    <Input placeholder="Introduce el DNI de su cónyuge" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre completo</p>}
                                    name="nombre"
                                    rules={[{ required: true, message: 'Por favor, introduce tu nombre y apellidos' }]}
                                >
                                    <Input placeholder="Introduce tu nombre y apellidos" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre completo de su cónyuge</p>}

                                    name="nombreConyuge"
                                    rules={[{ required: true, message: 'Por favor, introduce el nombre y apellidos de su cónyuge' }]}
                                >
                                    <Input placeholder="Introduce el nombre y apellidos de su cónyuge" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuántos hijos tienes?</p>}
                                    name="hijos"
                                    alert
                                    rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                                >
                                    <InputNumber style={{ width: "100%" }} defaultValue={0} min={0} onChange={onChangeHijos} disabled={buttonLoading} />
                                </Form.Item>
                                <div style={{ display: alertHijos ? "block" : "none" }}>
                                    <Alert message={textAlert} type={typeOfAlert} showIcon />
                                </div>

                                {cantidadHijos >= 2 && Array.from({ length: cantidadHijos }, (_, i) => i + 1).map(num => (
                                    <React.Fragment key={num}>
                                        <Form.Item
                                            style={{ marginBottom: "0.6rem" }}
                                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre Hijo {num}</p>} name={`nombreHijo${num}`}
                                            rules={[{ required: true, message: `Por favor, introduce el nombre del hijo ${num}` }]}
                                        >
                                            <Input placeholder={`Introduce el nombre del hijo ${num}`} disabled={buttonLoading} />
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginBottom: "0.6rem" }}
                                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Fecha de nacimiento hijo {num}</p>} name={`fechaNacimientoHijo${num}`}
                                            rules={[{ required: true, message: `Por favor, introduce la fecha de nacimiento del hijo ${num}` }]}
                                        >

                                            <DatePicker style={{ width: "100%" }} locale={locale} format={'DD/MM/YYYY'} placeholder={`Introduce la fecha de nacimiento del hijo ${num}`} disabled={buttonLoading} disabledDate={disabledDateBeforeToday} />
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginBottom: "0.6rem" }}
                                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>DNI Hijo {num}</p>} name={`dniHijo${num}`}
                                            rules={[{message: `Por favor, introduce el DNI del hijo ${num}` }]}
                                        >
                                            <Input placeholder={`Introduce el DNI del hijo ${num}`} disabled={buttonLoading} />
                                        </Form.Item>
                                    </React.Fragment>
                                ))}

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Dirección</p>}
                                    name="direccion"
                                    rules={[{ required: true, message: 'Por favor, introduce tu dirección' }]}
                                >
                                    <Input placeholder="Introduce tu dirección" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Código Postal</p>}
                                    name="codigoPostal"
                                    rules={[{ required: true, message: 'Por favor, introduce tu código postal' }]}
                                >
                                    <Input placeholder="Introduce tu código postal" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Provincia</p>}
                                    name="provincia"
                                    rules={[{ required: true, message: 'Por favor, introduce tu provincia' }]}
                                >
                                    <Input placeholder="Introduce tu provincia" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                                    <Checkbox disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                                </Form.Item>

                                <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }} disabled={alertAnoJubilacion || alertHijos}>
                                    {buttonLoading ? 'Enviando...' : 'Enviar'}
                                </Button>
                            </Form>

                            <Result
                                icon={<CheckCircleFilled style={{ color: "#00a854" }} />}
                                title="¡Formulario enviado correctamente!"
                                subTitle={
                                    <div>
                                        <p>Te hemos enviado el documento al correo electrónico. ¡Te recomendamos que lo revises!</p>
                                        <p style={{ color: "#00a854", fontSize: "12px" }}>También puedes descargarlo directamente pulsando el botón de abajo</p>
                                    </div>
                                }
                                extra={[
                                    <Button key="download" type="primary" onClick={() => window.open(complementoPaternidadFile, "_blank")}>
                                        Descargar documento
                                    </Button>
                                ]}
                                style={{ display: result, textAlign: "center", marginTop: "1rem" }}
                            />
                        </div>
                    </Card>
                </div>
            </ConfigProvider>
        </>
    );
};

export default FormComplementoPaternidad;
