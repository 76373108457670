import { useEffect, useState, useRef } from "react";
import changeHeight from "../../../data/changeHeight";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button, Alert } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { InputOTP } from "antd-input-otp";
import "../../../../src/otp.css";
dayjs.extend(customParseFormat);


const OTPVerification = ({ datos, onClick, goBack }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const nextStep = onClick;
    const prevStep = goBack;
    const divRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === divRef.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(divRef.current);
    
        return () => {
          resizeObserver.unobserve(divRef.current);
        };
      }, []);

    const handleVerificationCode = (value) => {
        setVerificationCode(value);
        setIsIncorrect(false);
    }

    const handleVerify = async () => {
        setButtonLoading(true);
        let inputCode = verificationCode;
        if (inputCode.length < 6) {
            setVerificationCode('');
            setIsIncorrect(true);
            setButtonLoading(false);
            return;
        }
        inputCode = inputCode.join('');
        try {
            let params = {
                phone: datos.tel,
                code: inputCode
            }
            await axios.get('https://z4ogsjzhbrcnqltbtjbcprvllq0kpjvv.lambda-url.eu-west-3.on.aws/', { params }).then(async (response) => {
                if (response.data.message === 'approved') {
                    await axios.patch('https://5xkk6tarwqfnjwy44g64ip4jjq0ofuzn.lambda-url.eu-west-3.on.aws/', { id: datos.submissionId, OTP: "OK" }).then((response) => {
                        setIsCorrect(true);
                        setButtonLoading(false);
                        setTimeout(() => {
                            nextStep();
                        }, 3000);
                    });
                } else {
                    setVerificationCode('');
                    setIsIncorrect(true);
                    setButtonLoading(false);
                }
            }).catch((error) => {
                setVerificationCode('');
                setIsIncorrect(true);
                setButtonLoading(false);
            });
        } catch (error) {
            setVerificationCode('');
            setIsIncorrect(true);
            setButtonLoading(false);
        }
    }

    const handleGoBack = async () => {
        prevStep();
    }

    // Tenemos que cambiar el tipo de los inputs con la clase "input-otp__field" a "number" para que el teclado numérico aparezca en móviles.
    // Tambien debemos quitar el contador de numeros para subir o bajar el contador de numeros para que no se vea en el input.
    useEffect(() => {
        const inputs = document.querySelectorAll('.input-otp__field');
        inputs.forEach(input => {
            input.type = 'number';
        });
    }, []);

    return (
        <div ref={divRef} className="form-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1.5rem' }}>
            <div className="form-title">
                <h2 style={{ fontSize: '1em' }}>Verificación SMS</h2>
            </div>
            <div className="form-content">
                <div className="form-description">
                    <p style={{ fontSize: '0.8em', textAlign: 'center' }}>Introduce el código de verificación que has recibido por SMS al número +{datos.tel}.</p>
                </div>
                <div className="form-fields">
                    <div className="form-field">
                        <InputOTP
                            length={6}
                            value={verificationCode}
                            onChange={handleVerificationCode}
                            inputType="numeric"
                            style={{ borderColor: isIncorrect ? 'red' : isCorrect ? 'green' : '' }}
                        />
                    </div>
                </div>
                <div>
                    {isIncorrect && <Alert message="Código incorrecto. Por favor, inténtalo de nuevo." type="error" showIcon style={{ marginTop: '30px' }} />}
                </div>
                <div style={{ marginBottom: '30px', marginTop: '30px' }} className="form-actions">
                    <Button type='primary' loading={buttonLoading} onClick={handleVerify} style={{ width: "100%", height: "35px", backgroundColor: isCorrect ? '#438600' : '' }} disabled={isCorrect}>
                        {isCorrect ? <CheckCircleOutlined style={{ color: "#FFFFFF" }}></CheckCircleOutlined> : buttonLoading ? 'Verificando...' : 'Verificar'}
                    </Button>
                    <Button type='default' onClick={handleGoBack} style={{ width: "100%", height: "35px", marginTop: '10px' }} disabled={buttonLoading || isCorrect}>
                        Volver
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default OTPVerification;