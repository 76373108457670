import React, { useState } from 'react';
import { Form, Input, Button, ConfigProvider, Result } from 'antd';
import axios from 'axios';
import { CheckCircleFilled } from '@ant-design/icons';

const FormAirtable = ({primaryColor, formData1, urlParent}) => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [form] = Form.useForm();
    const [step, setStep] = useState(0);

    console.log(formData1);

    const onFinish = async (values) => {
        setbuttonLoading(true);
        //añadir formData1 a values
        values = {...values, ...formData1};
        
        axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
            .then(function (response) {
                if (response.data.message === "OK") {
                    setbuttonLoading(false);
                    setStep(1);
                }
            })
            .catch(function () {
                setbuttonLoading(false);
            });

    };
    const subtitleText = "Tu información ha sido enviada correctamente.<br/>En breve nos pondremos en contacto contigo, gracias.";
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primaryColor,
                },
            }}
        >
            <style>
                {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
        
        {step === 0 && (
            <Form
                form={form}
                style={{ padding: '1.5rem' }}
                name="contactoAirtable"
                initialValues={{ remember: true, url: urlParent }} // Set initial value of url to urlParent
                layout='vertical'
                onFinish={onFinish}
                requiredMark={false}
            >
                <Form.Item
                    hidden={true}
                    label="URL"

                    name="url"
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item
                    hidden={true}
                    label="FB"
                    name="fbp">
                    <Input type='' />
                </Form.Item>

                <Form.Item
                    hidden={true}
                    label="Hubspotutk"
                    name="hubspotutk">
                    <Input type='' />
                </Form.Item>
                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te llamamos? *</p>}
                    name="tel"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                >
                    <Input maxLength={9} minLength={9} placeholder='619 99 31 31' type='tel' disabled={buttonLoading} />
                </Form.Item>

                <Form.Item
                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar el email? *</p>}
                    name="email"
                    style={{ marginBottom: "0.6rem" }}
                    rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                >
                    <Input placeholder='tunombre@gmail.com' type='email' disabled={buttonLoading} />
                </Form.Item>

                <Button type='primary' htmlType='submit' loading={buttonLoading} style={{ width: '100%', marginTop: 10 }}>Enviar</Button>
            </Form>
            )}

            {step === 1 && (
                <div>
                    
                        <Result
                            icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                            style={{ padding: "1.5rem", paddingTop: "3rem" }}
                            status="success"
                            title="¡Gracias!"
                            subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}

                        />
                </div>
            )}

        </ConfigProvider >
    );
};

export default FormAirtable;