import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, ConfigProvider, InputNumber, Radio, Space, notification, Checkbox, Select, Result } from 'antd';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import changeHeight from '../data/changeHeight';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;


const FormHefame = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [noAseguradora, setNoAseguradora] = useState(false);
    const [descuento, setDescuento] = useState(0);
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [value, setValue] = useState("");

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true)
        axios.post(`${process.env.REACT_APP_URL_CALCULO_RAPIDO}`, values)
            .then(function (response) {
                if (response.data.message === "OK") {
                    setbuttonLoading(false);
                    setSuccess(true);
                    if (values.procedencia === "NoSeguro") {
                        setNoAseguradora(true);
                    } else {
                        let descuento = values.importe * 0.7;
                        if (!Number.isInteger(descuento)) {
                            descuento = descuento.toFixed(2);
                        }
                        setDescuento(descuento);
                        changeHeight(refResult.current.clientHeight)
                    }
                    setResult("block");
                    changeHeight(heightResult);
                }
            })
            .catch(function () {
                openNotification('bottom');
                setbuttonLoading(false);
            });
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Inténtelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    let subtitleText = (!noAseguradora) ? "El importe de la póliza con Coventia sería de " + descuento + " € anual.<br>En breve uno de nuestros compañeros se pondrá en contacto con usted." : "En breve uno de nuestros compañeros se pondrá en contacto con usted.";

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Form
                        form={form}
                        style={{ padding: '1.5rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                        requiredMark={false}
                    >

                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="userAgent"
                            name="userAgent">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuánto pagas al año por tu seguro de vida? (€) *</p>}
                            name="importe"
                            style={{ marginBottom: "0.6rem" }}
                            colon={false}
                            rules={[{ required: true, message: 'Por favor, ingresa cuanto pagas al año por tu seguro de vida' }]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder='700' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Con quién tienes la póliza contratada actualmente? *</p>}
                            name="procedencia"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Indique la procedencia del seguro' }]}
                        >
                            <Radio.Group onChange={onChange} value={value}>
                                <Space
                                    direction='vertical'
                                    style={{ marginLeft: '1rem' }}
                                >
                                    <Radio value={"Banco"} style={{ fontWeight: 'bold' }}>Banco</Radio>
                                    <Radio value={"NoSeguro"} style={{ fontWeight: 'bold' }}>No tengo seguro a día de hoy</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre completo *</p>}
                            name="name"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu nombre completo' }]}
                        >
                            <Input placeholder='Tu nombre completo' type='text' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos llamar? *</p>}
                            name="tel"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input maxLength={9} minLength={9} placeholder='619 99 31 31' type='tel' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar el email? *</p>}
                            name="email"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@gmail.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuándo prefieres que te llamemos?</p>}
                            name="horadecontacto"
                            style={{ marginBottom: "0.6rem" }}
                        >
                            <Select placeholder="Elige una franja horaria">
                                <Option value="Mañana: 08:00 - 15:00">Mañana: 08:00 - 15:00</Option>
                                <Option value="Tarde: 15:00 - 18:00">Tarde: 15:00 - 18:00</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Observaciones</p>}
                            name="observaciones"
                            style={{ marginBottom: "0.6rem" }}
                        >
                            <TextArea rows={4} />

                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}
                        >
                            <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                                {buttonLoading ? 'Enviando...' : 'Enviar'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <div ref={refResult}>
                    <div style={{ display: `${result}` }}>
                        <Result
                            icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                            style={{ padding: '1.5rem', paddingTop: '3rem' }}
                            status="success"
                            title="¡Muchas gracias!"
                            subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}
                        />
                    </div>
                </div>

            </ConfigProvider>

        </>
    );
}

export default FormHefame;