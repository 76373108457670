import React, { useState } from 'react';
import { Form, InputNumber, Button, ConfigProvider } from 'antd';


const FormParte2 = ({ form, onFinishParte2, buttonLoading, primaryColor }) => {
    const [calculatedData, setCalculatedData] = useState(null);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primaryColor,
                },
            }}
        >
            <style>
                {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
                <Form
                    form={form}
                    style={{ padding: '1.5rem' }}
                    name="parte2"
                    onFinish={onFinishParte2}
                    initialValues={{ remember: true }}
                    layout='vertical'
                    requiredMark={false}
                >
                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Euribor (%) *
                        </p>}
                        name="euribor"
                        tooltip={{ title: '(Suele coincidir con la fecha de vencimiento de tu seguro actual)' }}
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa el Euribor' }]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder='2.00' disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Diferencial (%) *</p>}
                        name="differential"
                        tooltip={{ title: 'El diferencial es el porcentaje fijo que se suma al Euribor a la hipoteca. Si la hipoteca es a tipo fijo, pon el tipo de interés fijo aplicado. Se puede consultar en la banca electrónica o en la escritura.' }}
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa el Euribor' }]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder='0.3' disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item
                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Penalización (%) *</p>}
                        name="penalty"
                        tooltip={{ title: 'Es la penalizacion que aplica el banco sobre el tipo de interés por cancelar el seguro.' }}
                        style={{ marginBottom: "0.6rem" }}
                        rules={[{ required: true, message: 'Por favor, ingresa el Euribor' }]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder='0.3' disabled={buttonLoading} />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}>
                        <Button type='primary' htmlType="submit" style={{ width: "100%", height: "35px" }} loading={buttonLoading}>
                            Calcular
                        </Button>
                    </Form.Item>
                </Form>
        </ConfigProvider>
    );
}

export default FormParte2;
