import React, { useState, useEffect, useRef } from 'react';
import { Form, ConfigProvider, notification, Button, Row, Col, Spin, Card } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import changeHeight from '../../data/changeHeight';
import axios from 'axios';
import FormParte1 from './components/FormParte1';
import FormAirtable from './components/FormAirtable';

const FormCalcularEuribor = () => {
    const [formData1, setFormData1] = useState(null);
    const [calculatedData, setCalculatedData] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [urlParent, setUrlParent] = useState(null);
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const [api, contextHolder] = notification.useNotification();

    const [paso, setPaso] = useState(0);
    const handleCalcular = async () => {
        setButtonLoading(true);
        try {
            const { terms, url, ...params } = formData1;
            const response = await axios.get('https://r75z5ejdb6wa5j4ol6r23gj2h40noczo.lambda-url.eu-west-3.on.aws/', {
                params
            });
            setCalculatedData(response.data);
            setPaso(2);
        } catch (error) {
            console.error(error);
            openNotification('bottomRight');
        }
        setButtonLoading(false);
    };

    useEffect(() => {
        if (paso === 1) {
            setCalculatedData(handleCalcular(formData1));
        }
    }, [paso]);
        
    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
        }
        if (searchParams.get("parentUrl")) {
            setUrlParent(searchParams.get("parentUrl"));
            form.setFieldsValue({ url: decodeURIComponent(searchParams.get("parentUrl")) });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });
        resizeObserver.observe(refForm.current);
        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>

                <div ref={refForm}>
                    {
                        paso === 0 ? (
                            <FormParte1
                                form={form}
                                onFinishParte1={(values) => {
                                    setFormData1(values);
                                    setPaso(1);
                                }}
                                buttonLoading={buttonLoading}
                                primaryColor={primaryColor}
                            />
                        ) : paso === 1 ? (
                            <Row justify={'center'} align={'middle'} style={{ top: '50%' }} gutter={[20, 15]}>
                                <Spin spinning={buttonLoading}></Spin>
                            </Row>
                        ) : paso === 2 ? (
                            <Row justify={'center'} align={'middle'} style={{ top: '50%' }} gutter={[20, 15]}>
                                <Col span={20}></Col>
                                <Card style={{ marginLeft: 20, marginRight: 20 }}>
                                    <Col span={24}>
                                        <h2>Según los datos que nos has proporcionado:</h2>
                                        <p style={{ fontSize: 18 }}>
                                            Hemos estimado que pagas alrededor de <b>{calculatedData.pmtTotal}€</b> al mes en tu hipoteca. En la siguiente renovación usando como euribor <b>{calculatedData.nuevo_euribor}-{calculatedData.nuevo_euribor2}</b> deberías pagar entre <b>{calculatedData.pmtTotalNuevoEuribor}€ y {calculatedData.pmtTotalNuevoEuribor2}€ al mes.</b>
                                        </p>
                                    </Col>
                                    <Row justify={'end'}>
                                        <Col style={{ marginTop: 20 }}><Button type='primary' onClick={() => { setPaso(3) }}>¿Quieres mejorar las condiciones de tu hipoteca?</Button></Col>
                                    </Row>
                                </Card>
                            </Row>
                        ) : paso === 3 && (
                            <FormAirtable primaryColor={primaryColor} formData1={formData1} urlParent={urlParent} />
                        )
                    }

                </div>
            </ConfigProvider>
        </>
    );
}

export default FormCalcularEuribor;