import { useEffect, useState, useRef } from "react";
import changeHeight from "../../../data/changeHeight";


const ResultPaginaPrincipalParte3 = ({ datos }) => {

    const refResult3 = useRef(0);
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        changeHeight(refResult3.current.clientHeight)
    })

    useEffect(() => {
        setNombre(datos.name);
    }, [datos])

    return (
        <>
            <div ref={refResult3}>
                <div style={{ padding: '2rem' }}>
                    <p>¡Muchas gracias {nombre}! En breve te llegará el contrato con la solicitud de seguro para que puedas firmarlo</p>
                </div>
            </div>
        </>
    )

}

export default ResultPaginaPrincipalParte3;