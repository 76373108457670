import { Form, Input, Select, Switch, Upload, Button, Radio, Card, notification, Descriptions } from "antd";
import changeHeight from "../../../data/changeHeight";
import { useRef, useEffect, useState } from "react";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import paises from "../../../data/listaPaises"
import axios from "axios";
import imageCompression from 'browser-image-compression';
import BeneficiariosFormComponent from "../../../components/BeneficiariosFormComponent";


const FormularioParte3 = ({ datos, onClick, language }) => {

    const texts = {
        es: {
            resumen: "Resumen de la póliza",
            fechaInicio: "Fecha de inicio",
            precio: "Precio",
            sumaAsegurada: "Suma asegurada",
            garantia: "Garantía",
            p1: "¿Con qué periodicidad quieres pagar tu seguro? *",
            rule1: "Por favor, selecciona la periodicidad que quieres que tenga tu seguro",
            tooltip1: "No se pueden elegir opciones con pagos inferiores a 25€",
            p2: "¿Eres una persona expuesta políticamente? (PEPs)",
            p2_1: "Sí",
            p2_2: "No",
            p3: "Puesto *",
            rule3: "Por favor, indica su puesto como PEP",
            p4: "¿Es el Banco el beneficiario del seguro?",
            p4_1: "Sí",
            p4_2: "No",
            p5: "Banco beneficiario *",
            rule5: "Por favor, indica el préstamo que tiene con el banco",
            p6: "Beneficiarios del seguro *",
            rule6: "Por favor, indique quien es el beneficiario del seguro",
            p6_1: "Cláusula estándar",
            p6_2: "Personas designadas",
            p7: "Dirección *",
            rule7: "Por favor, ingresa su dirección",
            p8: "Código postal *",
            rule8: "Por favor, ingresa el código postal de su residencia actual",
            p9: "Nacionalidad *",
            rule9: "Por favor, introduzca su nacionalidad",
            p10: "IBAN (Cuenta corriente) *",
            rule10: "Por favor, introduzca el IBAN de su cuenta corriente",
            p11: "DNI (Por ambas caras en el mismo documento) *",
            rule11: "Por favor, suba una foto de la parte delantera de su DNI",
            p12: "DNI (Parte trasera)",
            tooltip12: "Si la foto subida en el anterior apartado no cuenta con la parte trasera del DNI, súbela aquí, por favor",
            dragger12: "Haz clic o arrastra el archivo aquí",
            hint12: "Solo se permite subir imágenes",
            p13: "Último recibo pagado del seguro *",
            rule13: "Por favor, suba una foto de su último recibo pagado del seguro",
            button: "Enviar",
            enviando: "Enviando...",
            error: "Ha ocurrido un error",
            errorDesc: "Ha ocurrido un error inesperado, por favor inténtelo más tarde"
        },
        en: {
            resumen: "Policy summary",
            fechaInicio: "Start date",
            precio: "Price",
            sumaAsegurada: "Insured sum",
            garantia: "Warranty",
            p1: "How often do you want to pay your insurance? *",
            rule1: "Please select how often you want your insurance to be paid",
            tooltip1: "Options with payments below 25€ cannot be chosen",
            p2: "Are you a politically exposed person? (PEPs)",
            p2_1: "Yes",
            p2_2: "No",
            p3: "Position *",
            rule3: "Please indicate your position as PEP",
            p4: "Is the Bank the beneficiary of the insurance?",
            p4_1: "Yes",
            p4_2: "No",
            p5: "Bank beneficiary *",
            rule5: "Please indicate the loan you have with the bank",
            p6: "Insurance beneficiaries *",
            rule6: "Please indicate who is the beneficiary of the insurance",
            p6_1: "Standard clause",
            p6_2: "Designated persons",
            p7: "Address *",
            rule7: "Please enter your address",
            p8: "Postal code *",
            rule8: "Please enter the postal code of your current residence",
            p9: "Nationality *",
            rule9: "Please enter your nationality",
            p10: "IBAN (Current account) *",
            rule10: "Please enter the IBAN of your current account",
            p11: "ID (On both sides in the same document) *",
            rule11: "Please upload a photo of the front of your ID",
            p12: "ID (Back)",
            tooltip12: "If the photo uploaded in the previous section does not have the back of the ID, upload it here, please",
            dragger12: "Click or drag the file here",
            hint12: "Only images are allowed",
            p13: "Last paid insurance receipt *",
            rule13: "Please upload a photo of your last paid insurance receipt",
            button: "Submit",
            enviando: "Sending...",
            error: "An error has occurred",
            errorDesc: "An unexpected error has occurred, please try again later"
        }
    }


    const refForm3 = useRef(0);
    const [form] = Form.useForm();
    const [fraccion, setFraccion] = useState([]);
    const [PEP, setPEP] = useState(false);
    const [beneficiario, setBeneficiario] = useState(false);
    const [designado, setDesignado] = useState("");
    const [fechaFinSeguro, setFechaFinSeguro] = useState("");
    const [fileListDNI, setFileListDNI] = useState([]);
    const [fileListTrasera, setFileListTrasera] = useState([]);
    const [fileListRecibo, setFileListRecibo] = useState([]);
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const { Dragger } = Upload;

    useEffect(() => {

        if (datos.fin_seguro) {
            const fecha = new Date(datos.fin_seguro.$y, datos.fin_seguro.$M, datos.fin_seguro.$D);
            setFechaFinSeguro(fecha.toLocaleDateString());
        }

        comprobacionPrecio();
        form.setFieldsValue({ pep: PEP.toString(), banco_beneficiario: beneficiario.toString() })

    }, [datos])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === refForm3.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(refForm3.current);
    
        return () => {
          resizeObserver.unobserve(refForm3.current);
        };
    }, []);

      //Compresor de imágenes

      const compressImage = async (file) => {
        const maxSizeMB = 1.5;
        const options = {
          maxSizeMB,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
      
        try {
          const compressedBlob = await imageCompression(file, options);
          const compressedFile = new File([compressedBlob], file.name, { type: file.type });
          return compressedFile;
        } catch (error) {
          return file;
        }
      };


    const propsDNI = {
        onRemove: () => {
            setFileListDNI([]);
        },
        beforeUpload: async (file) => {
            if (file.size <= 1.5 * 1024 * 1024) {
              setFileListDNI([file]);
              return false;
            } else {
              const compressedFile = await compressImage(file);            
              setFileListDNI([compressedFile]);
              return false;
            }
          },
        fileList: fileListDNI
    };

    const propsTrasera = {
        onRemove: () => {
            setFileListTrasera([]);
        },
        beforeUpload: async (file) => {
            if (file.size <= 1.5 * 1024 * 1024) {
              setFileListTrasera([file]);
              return false;
            } else {
              const compressedFile = await compressImage(file);
              setFileListTrasera([compressedFile]);
              return false;
            }
          },
        fileList: fileListTrasera
    };

    const propsRecibo = {
        onRemove: () => {
            setFileListRecibo([]);
        },
        beforeUpload: async (file) => {
            if (file.size <= 1.5 * 1024 * 1024) {
              setFileListRecibo([file]);
              return false;
            } else {
              const compressedFile = await compressImage(file);
              setFileListRecibo([compressedFile]);
              return false;
            }
          },
        fileList: fileListRecibo
    };

    function onFinish(values) {
        setbuttonLoading(true);

        const formData = new FormData();

        fileListDNI.forEach((file) => {
            formData.append('dni', file);
        })

        fileListRecibo.forEach((file) => {
            formData.append('recibo_poliza', file);
        })

        if (fileListTrasera.length > 0) {
            fileListTrasera.forEach((file) => {
                formData.append('dni_trasera', file);
            })
        }


        formData.append("presupuesto_id", datos.presupuesto_id)


        formData.append("fraccionamiento", values.fraccionamiento);
        formData.append("pep", (values.pep).toString());

        if (values.pep === true) {
            formData.append("pep_puesto", values.pep_puesto);
        } else {
            formData.append("pep_puesto", " ");
        }

        formData.append("banco_beneficiario", (values.banco_beneficiario).toString());

        if (values.banco_beneficiario === true) {
            formData.append("banco_prestamo", values.banco_prestamo);
        } else {
            formData.append("banco_prestamo", " ");
        }

        if (values.beneficiario === "Cláusula estándar") {
            formData.append("beneficiario", "false");
        } else {
            formData.append("beneficiario", "true");
        }

        if (values.beneficiario === "Personas designadas") {
            formData.append("beneficiario_designados", JSON.stringify(values.beneficiarios));
        } else {
            formData.append("beneficiario_designados", " ");
        }

        formData.append("direccion", values.direccion);
        formData.append("codigo_postal", values.codigo_postal);
        formData.append("nacionalidad", values.nacionalidad);
        formData.append("iban", values.iban);




        const headers = {
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>"
        }


        try {
            axios.post(process.env.REACT_APP_URL_CARGAR_IMAGENES, formData, {
                headers: headers,
            })
            .then((res) => res.body !== undefined && (setbuttonLoading(false), onClick()))
            .catch((error) => {
                console.log(error)
                setbuttonLoading(false);
                if (error.response.body !== undefined) {
                    openNotification('bottom', error.response.body);
                } else {
                    openNotification('bottom', texts[language].errorDesc);
                }
            })
        } catch (error) {
            console.log(error)
            setbuttonLoading(false);
            openNotification('bottom', texts[language].errorDesc);
        }


        onClick();
        setbuttonLoading(false);

    }

    function comprobacionPrecio() {

        let fraccion = [];

        if (datos.pago_mensual === "No permitido" || datos.pago_mensual === undefined) {
            fraccion.push({ value: 'Mensualmente', label: `Mensualmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Mensualmente', label: `Mensualmente - ${datos.pago_mensual} ` })
        }

        if (datos.pago_trimestral === "No permitido" || datos.pago_trimestral === undefined) {
            fraccion.push({ value: 'Trimestralmente', label: `Trimestralmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Trimestralmente', label: `Trimestralmente - ${datos.pago_trimestral}` })
        }

        if (datos.pago_semestral === "No permitido" || datos.pago_semestral === undefined) {
            fraccion.push({ value: 'Semestralmente', label: `Semestralmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Semestralmente', label: `Semestralmente - ${datos.pago_semestral}` })
        }

        if (datos.pago_anual === "No permitido" || datos.pago_anual === undefined) {
            fraccion.push({ value: 'Anualmente', label: `Anualmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Anualmente', label: `Anualmente - ${datos.pago_anual}` })
        }

        setFraccion(fraccion);

    }



    function cambioPEP(value) {
        setPEP(value);
    }

    function cambioBeneficiario(value) {
        setBeneficiario(value);
    }

    const cambioDesignado = (e) => {
        setDesignado(e.target.value);
    }

    const openNotification = (placement, message) => {
        api.info({
            message: texts[language].error,
            description: `${message}`,
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    return (
        <>
            {contextHolder}
            <div ref={refForm3}>
                <div>
                    <div style={{ padding: '1.5rem' }}>
                        <Descriptions title={texts[language].resumen} bordered>
                            <Descriptions.Item span={3} label={texts[language].fechaInicio}>{fechaFinSeguro !== undefined ? fechaFinSeguro : "Fecha de renovación"}</Descriptions.Item>
                            <Descriptions.Item span={3} label={texts[language].precio}>{datos.poliza_nueva !== undefined ? datos.poliza_nueva + ' €' : "Precio"}</Descriptions.Item>
                            <Descriptions.Item span={3} label={texts[language].sumaAsegurada}>{datos.suma_asegurada_introducida !== undefined ? datos.suma_asegurada_introducida + ' €' : "Suma asegurada"}</Descriptions.Item>
                            <Descriptions.Item span={3} label={texts[language].garantia}>{datos.cobertura !== undefined ? datos.cobertura : "Cobertura"}</Descriptions.Item>
                        </Descriptions>
                    </div>

                    <Form
                        form={form}
                        style={{ padding: "2rem", marginTop: "-1rem" }}
                        name="basic3"
                        layout="vertical"
                        onFinish={onFinish}
                        requiredMark={false}
                        >

                        <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p1}</p>}
                                name="fraccionamiento"
                                style={{ marginBottom: "0.6rem" }}
                                rules={[
                                    { required: true, message: texts[language].rule1 }
                                ]}
                                tooltip={{ title: texts[language].tooltip1 }}
                            >
                                <Select showSearch disabled={buttonLoading}>
                                    {fraccion.map((fraccion) => (
                                        <Select.Option key={fraccion.value} value={fraccion.value} disabled={fraccion.disabled}>
                                            {fraccion.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p2}</p>}
                                name="pep"
                            >
                                <Switch checkedChildren={texts[language].p2_1} unCheckedChildren={texts[language].p2_2} checked={PEP} onChange={cambioPEP} disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p3}</p>}
                                name="pep_puesto"
                                style={{ display: `${PEP ? 'block' : 'none'}` }}
                                rules={[{ required: PEP, message: texts[language].rule3 }]}
                            >
                                <Input type="text" disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p4}</p>}
                                name="banco_beneficiario"
                            >
                                <Switch checkedChildren={texts[language].p4_1} unCheckedChildren={texts[language].p4_2} checked={beneficiario} onChange={cambioBeneficiario} disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p5}</p>}
                                name="banco_prestamo"
                                style={{ display: `${beneficiario ? 'block' : 'none'}` }}
                                rules={[{ required: beneficiario, message: texts[language].rule5 }]}
                            >
                                <Input type="text" disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p6}</p>}                            
                                name="beneficiario"
                                style={{ marginBottom: "0.6rem" }}
                                rules={[{ required: true, message: texts[language].rule6 }]}
                            >
                                <Radio.Group buttonStyle="solid" onChange={cambioDesignado} disabled={buttonLoading}>
                                    <Radio.Button value="Cláusula estándar">{texts[language].p6_1}</Radio.Button>
                                    <Radio.Button value="Personas designadas">{texts[language].p6_2}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <BeneficiariosFormComponent designado={designado} form={form} buttonLoading={buttonLoading} setCanSubmit={setCanSubmit} language={language} />

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p7}</p>}
                                name="direccion"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule7 }]}
                            >
                                <Input type="text" disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p8}</p>}
                                name="codigo_postal"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule8 }]}
                            >
                                <Input type="text" disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p9}</p>}
                                name="nacionalidad"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule9 }]}
                            >
                                <Select showSearch disabled={buttonLoading}>
                                    {paises.map((pais) => (
                                        <Select.Option key={pais} value={pais}>
                                            {pais}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p10}</p>}
                                name="iban"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule10 }]}
                            >
                                <Input type="text" disabled={buttonLoading} />
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p11}</p>}
                                name="dni"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule11 }]}
                            >
                                <Dragger {...propsDNI} disabled={buttonLoading}>
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">{texts[language].dragger12}</p>
                                    <p className="ant-upload-hint">{texts[language].hint12}</p>
                                </Dragger>
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p12}</p>}
                                name="dni_trasera"
                                style={{marginBottom: "0.6rem"}}
                                tooltip={{ title: texts[language].tooltip12 }}
                            >
                                <Dragger {...propsTrasera} disabled={buttonLoading}>
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">{texts[language].dragger12}</p>
                                    <p className="ant-upload-hint">{texts[language].hint12}</p>
                                </Dragger>
                            </Form.Item>

                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>{texts[language].p13}</p>}
                                name="recibo_poliza"
                                style={{marginBottom: "0.6rem"}}
                                rules={[{ required: true, message: texts[language].rule13 }]}
                            >
                                <Dragger {...propsRecibo} disabled={buttonLoading}>
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">{texts[language].dragger12}</p>
                                    <p className="ant-upload-hint">{texts[language].hint12}</p>
                                </Dragger>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: "0.6rem" }}>
                                <Button style={{ width: "100%", height: "35px", marginTop:"0.8rem" }} type="primary" loading={buttonLoading} htmlType="submit" disabled={!canSubmit}>
                                    {buttonLoading ? texts[language].enviando : texts[language].button}
                                </Button>
                            </Form.Item>

                    </Form>
                </div>
            </div>
        </>
    );


}

export default FormularioParte3;