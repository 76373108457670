import { Form, Input, Select, Switch, Upload, Button, Radio, Card, notification, Descriptions } from "antd";
import changeHeight from "../../../data/changeHeight";
import { useRef, useEffect, useState } from "react";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import paises from "../../../data/listaPaises"
import axios from "axios";
import imageCompression from 'browser-image-compression';
import BeneficiariosFormComponent from "../../../components/BeneficiariosFormComponent";


const FormularioParte3 = ({ datos, onClick }) => {

    const refForm3 = useRef(0);
    const [form] = Form.useForm();
    const [fraccion, setFraccion] = useState([]);
    const [PEP, setPEP] = useState(false);
    const [beneficiario, setBeneficiario] = useState(false);
    const [designado, setDesignado] = useState("");
    const [fechaFinSeguro, setFechaFinSeguro] = useState("");
    const [fileListDNI, setFileListDNI] = useState([]);
    const [fileListRecibo, setFileListRecibo] = useState([]);
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const { Dragger } = Upload;

    useEffect(() => {

        if (datos.fin_seguro) {
            const fecha = new Date(datos.fin_seguro.$y, datos.fin_seguro.$M, datos.fin_seguro.$D);
            setFechaFinSeguro(fecha.toLocaleDateString());
        }

        comprobacionPrecio();
        form.setFieldsValue({ pep: PEP.toString(), banco_beneficiario: beneficiario.toString() })

    }, [datos])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm3.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm3.current);

        return () => {
            resizeObserver.unobserve(refForm3.current);
        };
    }, []);

    //Compresor de imágenes

    const compressImage = async (file) => {
        const maxSizeMB = 1.5;
        const options = {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        try {
            const compressedBlob = await imageCompression(file, options);
            const compressedFile = new File([compressedBlob], file.name, { type: file.type });
            return compressedFile;
        } catch (error) {
            return file;
        }
    };


    const propsDNI = {
        onRemove: () => {
            setFileListDNI([]);
        },
        beforeUpload: async (file) => {
            if (file.size <= 1.5 * 1024 * 1024) {
                setFileListDNI([file]);
                return false;
            } else {
                const compressedFile = await compressImage(file);
                setFileListDNI([compressedFile]);
                return false;
            }
        },
        fileList: fileListDNI
    };

    const propsRecibo = {
        onRemove: () => {
            setFileListRecibo([]);
        },
        beforeUpload: async (file) => {
            if (file.size <= 1.5 * 1024 * 1024) {
                setFileListRecibo([file]);
                return false;
            } else {
                const compressedFile = await compressImage(file);
                setFileListRecibo([compressedFile]);
                return false;
            }
        },
        fileList: fileListRecibo
    };

    function onFinish(values) {
        setbuttonLoading(true);

        const formData = new FormData();

        fileListDNI.forEach((file) => {
            formData.append('dni', file);
        })

        fileListRecibo.forEach((file) => {
            formData.append('recibo_poliza', file);
        })

        formData.append("presupuesto_id", datos.presupuesto_id)


        formData.append("fraccionamiento", values.fraccionamiento);
        formData.append("pep", (values.pep).toString());

        if (values.pep === true) {
            formData.append("pep_puesto", values.pep_puesto);
        } else {
            formData.append("pep_puesto", " ");
        }

        formData.append("banco_beneficiario", (values.banco_beneficiario).toString());

        if (values.banco_beneficiario === true) {
            formData.append("banco_prestamo", values.banco_prestamo);
        } else {
            formData.append("banco_prestamo", " ");
        }

        if (values.beneficiario === "Cláusula estándar") {
            formData.append("beneficiario", "false");
        } else {
            formData.append("beneficiario", "true");
        }

        if (values.beneficiario === "Personas designadas") {
            formData.append("beneficiario_designados", JSON.stringify(values.beneficiarios));
        } else {
            formData.append("beneficiario_designados", " ");
        }

        formData.append("direccion", values.direccion);
        formData.append("codigo_postal", values.codigo_postal);
        formData.append("nacionalidad", values.nacionalidad);
        formData.append("iban", values.iban);




        const headers = {
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>"
        }


        try {
            axios.post(process.env.REACT_APP_URL_CARGAR_IMAGENES, formData, {
                headers: headers,
            })
                .then((res) => res.body !== undefined && (setbuttonLoading(false), onClick()))
                .catch((error) => {
                    console.log(error)
                    setbuttonLoading(false);
                    if (error.response.body !== undefined) {
                        openNotification('bottom', error.response.body);
                    } else {
                        openNotification('bottom', 'Ha ocurrido un error inesperado, por favor inténtelo más tarde');
                    }
                })
        } catch (error) {
            console.log(error)
            setbuttonLoading(false);
            openNotification('bottom', 'Ha ocurrido un error inesperado, por favor inténtelo más tarde')
        }


        onClick();
        setbuttonLoading(false);

    }

    function comprobacionPrecio() {

        let fraccion = [];

        if (datos.pago_mensual === "No permitido" || datos.pago_mensual === undefined) {
            fraccion.push({ value: 'Mensualmente', label: `Mensualmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Mensualmente', label: `Mensualmente - ${datos.pago_mensual} ` })
        }

        if (datos.pago_trimestral === "No permitido" || datos.pago_trimestral === undefined) {
            fraccion.push({ value: 'Trimestralmente', label: `Trimestralmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Trimestralmente', label: `Trimestralmente - ${datos.pago_trimestral}` })
        }

        if (datos.pago_semestral === "No permitido" || datos.pago_semestral === undefined) {
            fraccion.push({ value: 'Semestralmente', label: `Semestralmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Semestralmente', label: `Semestralmente - ${datos.pago_semestral}` })
        }

        if (datos.pago_anual === "No permitido" || datos.pago_anual === undefined) {
            fraccion.push({ value: 'Anualmente', label: `Anualmente`, disabled: true })
        } else {
            fraccion.push({ value: 'Anualmente', label: `Anualmente - ${datos.pago_anual}` })
        }

        setFraccion(fraccion);

    }



    function cambioPEP(value) {
        setPEP(value);
    }

    function cambioBeneficiario(value) {
        setBeneficiario(value);
    }

    const cambioDesignado = (e) => {
        setDesignado(e.target.value);
    }

    const openNotification = (placement, message) => {
        api.info({
            message: 'Ha ocurrido un error',
            description: `${message}`,
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    return (
        <>
            {contextHolder}
            <div ref={refForm3}>
                <div>
                    <div style={{ padding: '1.5rem' }}>
                        <Descriptions title="Resumen de la póliza" bordered>
                            <Descriptions.Item span={3} label="Fecha de inicio">{fechaFinSeguro !== undefined ? fechaFinSeguro : "Fecha de renovación"}</Descriptions.Item>
                            <Descriptions.Item span={3} label="Precio">{datos.poliza_nueva !== undefined ? datos.poliza_nueva + ' €' : "Precio"}</Descriptions.Item>
                            <Descriptions.Item span={3} label="Suma asegurada">{datos.suma_asegurada_introducida !== undefined ? datos.suma_asegurada_introducida + ' €' : "Suma asegurada"}</Descriptions.Item>
                            <Descriptions.Item span={3} label="Garantía">{datos.cobertura !== undefined ? datos.cobertura : "Cobertura"}</Descriptions.Item>
                        </Descriptions>
                    </div>

                    <Form
                        form={form}
                        style={{ padding: "2rem", marginTop: "-1rem" }}
                        name="basic3"
                        layout="vertical"
                        onFinish={onFinish}
                        requiredMark={false}
                    >

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Con qué periodicidad quieres pagar tu seguro? *</p>}
                            name="fraccionamiento"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[
                                { required: true, message: 'Por favor, selecciona la periodicidad que quieres que tenga tu seguro' }
                            ]}
                            tooltip={{ title: 'No se pueden elegir opciones con pagos inferiores a 25€' }}
                        >
                            <Select showSearch disabled={buttonLoading}>
                                {fraccion.map((fraccion) => (
                                    <Select.Option key={fraccion.value} value={fraccion.value} disabled={fraccion.disabled}>
                                        {fraccion.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Eres una persona expuesta políticamente? (PEPs) *</p>}
                            name="pep"
                        >
                            <Switch checkedChildren="Sí" unCheckedChildren="No" checked={PEP} onChange={cambioPEP} disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label="Puesto *"
                            name="pep_puesto"
                            style={{ display: `${PEP ? 'block' : 'none'}` }}
                            rules={[{ required: PEP, message: 'Por favor, indica su puesto como PEP' }]}
                        >
                            <Input type="text" disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label="¿Es el Banco el beneficiario del seguro? *"
                            name="banco_beneficiario"
                        >
                            <Switch checkedChildren="Sí" unCheckedChildren="No" checked={beneficiario} onChange={cambioBeneficiario} disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label="Banco beneficiario *"
                            name="banco_prestamo"
                            style={{ display: `${beneficiario ? 'block' : 'none'}` }}
                            rules={[{ required: beneficiario, message: 'Por favor, indica el préstamo que tiene con el banco' }]}
                        >
                            <Input type="text" disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Beneficiarios del seguro *</p>}
                            name="beneficiario"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: "Por favor, indique quien es el beneficiario del seguro" }]}
                        >
                            <Radio.Group buttonStyle="solid" onChange={cambioDesignado} disabled={buttonLoading}>
                                <Radio.Button value="Cláusula estándar">Cláusula estándar</Radio.Button>
                                <Radio.Button value="Personas designadas">Personas designadas</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <BeneficiariosFormComponent form={form} designado={designado} buttonLoading={buttonLoading} setCanSubmit={setCanSubmit} language={"es"} />

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Dirección *</p>}
                            name="direccion"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa su dirección' }]}
                        >
                            <Input type="text" disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Código postal *</p>}
                            name="codigo_postal"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa el código postal de su residencia actual' }]}
                        >
                            <Input type="text" disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nacionalidad *</p>}
                            name="nacionalidad"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, introduzca su nacionalidad' }]}
                        >
                            <Select showSearch disabled={buttonLoading}>
                                {paises.map((pais) => (
                                    <Select.Option key={pais} value={pais}>
                                        {pais}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>IBAN (Cuenta corriente) *</p>}
                            name="iban"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, introduzca el IBAN de su cuenta corriente' }]}
                        >
                            <Input type="text" disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>DNI (Por ambas caras en el mismo documento)</p>}
                            name="dni"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: "Por favor, suba una foto de la parte delantera de su DNI" }]}
                        >
                            <Dragger {...propsDNI} disabled={buttonLoading}>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">Haz clic o arrastra el archivo aquí</p>
                                <p className="ant-upload-hint">Solo se permite subir imágenes</p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Último recibo pagado del seguro *</p>}
                            name="recibo_poliza"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: "Por favor, suba una foto de su último recibo pagado del seguro" }]}
                        >
                            <Dragger {...propsRecibo} disabled={buttonLoading}>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">Haz clic o arrastra el archivo aquí</p>
                                <p className="ant-upload-hint">Solo se permite subir imágenes</p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }}>
                            <Button style={{ width: "100%", height: "35px", marginTop: "0.8rem" }} type="primary" loading={buttonLoading} htmlType="submit" disabled={!canSubmit}>
                                {buttonLoading ? 'Enviando...' : 'Enviar'}
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
            </div>
        </>
    );


}

export default FormularioParte3;